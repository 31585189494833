// eslint-disable-next-line max-classes-per-file
import {
  WaitListPurchase,
  PostalAddress,
  WaitListConfig,
  Entry as WLEntry,
  Category,
  Organizer,
  TicketingPlatform,
  EventPersonalization,
  Automation,
  CategoriesGroup,
  TicketTemplate,
  PayoutPolicy,
} from '.';
import { RecursivePartial, Utils } from '../shared/utils';

export interface EventCreateOptions {
  eventTemplate?: Event;
  removeExpired?: boolean;
  splitSessions?: boolean;
  organizerId?: string;
  organizer?: Organizer;
  priceScale?: number;
  defaultStatus?: EventStatus;
}

export class EventSearchOptions {
  sort: 'popularity' | 'date';
  searchQuery: string;
  displayStyle?: 'list' | 'map';

  constructor(options?: Partial<EventSearchOptions>) {
    this.sort = 'popularity';
    this.displayStyle = 'list';
    Object.assign(this, options);
  }
}

export enum EventStatus {
  Online = 'online',
  Paused = 'paused',
  Created = 'created',
  Expired = 'expired',
  Canceled = 'canceled',
  Private = 'private',
  Scheduled = 'scheduled',
  Error = 'error',
  Template = 'template',
}

export const eventStatusInfos = {
  [EventStatus.Online]: {
    identifier: EventStatus.Online,
    color: 'reelax-green',
    icon: 'check_circle',
  },
  [EventStatus.Private]: {
    identifier: EventStatus.Private,
    color: 'reelax-green',
    icon: 'lock',
  },
  [EventStatus.Created]: {
    identifier: EventStatus.Created,
    color: 'reelax-orange',
    icon: 'create',
  },
  [EventStatus.Scheduled]: {
    identifier: EventStatus.Scheduled,
    color: 'reelax-orange',
    icon: 'schedule',
  },
  [EventStatus.Paused]: {
    identifier: EventStatus.Paused,
    color: 'reelax-orange',
    icon: 'pause_circle_filled',
  },
  [EventStatus.Expired]: {
    identifier: EventStatus.Expired,
    frenchName: 'Terminé',
    tooltip: 'Événement terminé',
    color: 'red',
    icon: 'timer_off',
  },
  [EventStatus.Canceled]: {
    identifier: EventStatus.Canceled,
    color: 'red',
    icon: 'cancel',
  },
  [EventStatus.Error]: {
    identifier: EventStatus.Error,
    color: 'red',
    icon: 'error',
  },
  [EventStatus.Template]: {
    identifier: EventStatus.Template,
    color: 'reelax-blue',
    icon: 'design_services',
  },
};

export class StatusInfo {
  identifier: string;
  frenchName: string;
  tooltip: string;
  color: string;
  icon: string;

  constructor(options?: any) {
    if (options) {
      this.identifier = options.identifier;
      this.frenchName = options.frenchName;
      this.tooltip = options.tooltip;
      this.color = options.color;
      this.icon = options.icon;
    }
  }
}

export class Event {
  public allowPartnerUpdate: boolean;
  public allowPayinRefund: boolean;
  public name: string;
  public organizerName: string;
  public organizerTerms: string;
  /**
   * @deprecated please use postal address model now
   */
  public location: string;
  public dateStart: Date;
  public dateEnd: Date;
  public resaleEnd: Date;
  public imgUrl: string;
  public color: string;
  public secondaryColor: string;
  public backgroundColor: string;
  public address: string;
  public createdAt: Date;
  public updatedAt: Date;
  public seatingPlanUrl: string;
  public status: EventStatus;
  public isPartner: boolean;
  public TicketingPlatforms: TicketingPlatform[];
  public Categories: Category[];
  public CategoriesGroups: CategoriesGroup[];
  public EventPersonalization: EventPersonalization;
  public TicketTemplates: TicketTemplate[];
  public renewBarcode: boolean;
  public url: string;
  public capacity: number;
  public Organizers: Organizer[];
  public WaitLists?: WLEntry[];
  public WaitListPurchases?: WaitListPurchase[];
  public WaitListConfig?: WaitListConfig;
  public Automations?: Automation[];
  public id: number;
  public totalTicketSold?: number;
  public lastTicketSold?: number;
  public memo: string;
  public keywords: string;
  public tvaRate: number;
  public ticketShop: string;// url
  public modifiedBy: number;
  public postalAddressId: number;
  public PostalAddress?: PostalAddress;
  public EventUrls?: EventUrl[];

  constructor(options?: Partial<Event> & {description?: string}) {
    this.keywords = options.description;
    Object.assign(this, options);

    this.dateStart = options.dateStart ? new Date(options.dateStart) : null;
    this.dateEnd = options.dateEnd ? new Date(options.dateEnd) : null;
    this.resaleEnd = options.resaleEnd ? new Date(options.resaleEnd) : null;

    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;

    this.TicketingPlatforms = options.TicketingPlatforms?.map((t) => new TicketingPlatform(t));
    this.Categories = options.Categories?.map((c) => new Category(c))
      .sort( (a,b) => (a.order - b.order === 0) ? (a.updatedAt <= b.updatedAt ? 1 : -1) : a.order - b.order );
    this.EventPersonalization = new EventPersonalization(options?.EventPersonalization);
    this.TicketTemplates = options.TicketTemplates?.map((t) => new TicketTemplate(t));
    this.WaitListConfig = new WaitListConfig(options.WaitListConfig);
    this.WaitLists = options.WaitLists?.map((wl) => new WLEntry(wl));
    this.WaitListPurchases = options.WaitListPurchases?.map((wlp) => new WaitListPurchase(wlp));
    this.Organizers = options.Organizers?.map( (o) => new Organizer(o));
    this.Automations = options.Automations?.map( (a) => new Automation(a));
    this.CategoriesGroups = (
      options.CategoriesGroups ? options.CategoriesGroups.map( (cg) => new CategoriesGroup(cg, this.Categories)) : []
    ).sort( (a,b) => (a.order - b.order === 0) ? (a.updatedAt <= b.updatedAt ? 1 : -1) : a.order - b.order );
    this.PostalAddress = new PostalAddress(options.PostalAddress);
    this.EventUrls = options.EventUrls?.map((u) => new EventUrl(u));
  }

  public getGeneralInformations(): Partial<Event> {
    return {
      allowPayinRefund: this.allowPayinRefund,
      allowPartnerUpdate: this.allowPartnerUpdate,
      name: this.name,
      organizerName: this.organizerName,
      organizerTerms: this.organizerTerms,
      location: this.location,
      postalAddressId: this.postalAddressId,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      resaleEnd: this.resaleEnd,
      imgUrl: this.imgUrl,
      color: this.color,
      secondaryColor: this.secondaryColor,
      backgroundColor: this.backgroundColor,
      address: this.address,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      seatingPlanUrl: this.seatingPlanUrl,
      status: this.status,
      tvaRate: this.tvaRate,
      isPartner: this.isPartner,
      renewBarcode: this.renewBarcode,
      url: this.url,
      capacity: this.capacity,
      id: this.id,
      totalTicketSold: this.totalTicketSold,
      lastTicketSold: this.lastTicketSold,
      Organizers: this.Organizers,
      memo: this.memo,
      keywords: this.keywords,
    };
  }

  public get allCurrencies() {
    return Utils.currenciesOfCategories(this.Categories);
  }
}

export class EventWithStat extends Event {
  public fees: number;
  public ticketsNb: number;
  public sale: number; // Sale + PrivateSale + FriendSale
  public pendingPurchase: number;
  // eslint-disable-next-line max-len
  // PendingPurchase + PendingSwap + PendingValidation + PendingTicketDelivery + PendingPayment + PendingSellerConfirmation
  public organizerFeesFlat: number;
  public organizerFeesShare: number;
  public waitList: number;
  public activeWaitlist ?:number;
  public organizerFeesTotal: number;
  public 'Organizers.names': string;
  public 'TicketingPlatforms.platforms': string;
  /** @deprecated */
  public 'TicketingPlatforms.payoutAuto': boolean;
  public 'TicketingPlatforms.payoutPolicy': PayoutPolicy;

  constructor(options?: Partial<EventWithStat> & {description?: string}) {
    super(options);
    this.fees = options.fees;
    this.ticketsNb = options.ticketsNb;
    this.sale = options.sale ?? 0;
    this.pendingPurchase = options.pendingPurchase ?? 0;
    this.organizerFeesFlat = options.organizerFeesFlat ?? 0;
    this.organizerFeesShare = options.organizerFeesShare ?? 0;
    this.waitList = options.waitList;
    this.activeWaitlist = options.activeWaitlist || 0;
    this.organizerFeesTotal = +this.organizerFeesFlat + (+this.organizerFeesShare / 10000);
    this['Organizers.names'] = options['Organizers.names'];
    this['TicketingPlatforms.platforms'] = options['TicketingPlatforms.platforms'];
    this['TicketingPlatforms.payoutAuto'] = options['TicketingPlatforms.payoutAuto'];
    this['TicketingPlatforms.payoutPolicy'] = options['TicketingPlatforms.payoutPolicy'];
  }
}

export type EventToCreate = RecursivePartial<Event> & {
  tempId?: string;
  reelaxEvent?: Event;
};

export class EventUrl {
  id: number;
  url: string;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options);
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    }
  }
}
