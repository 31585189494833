// eslint-disable-next-line max-classes-per-file
import { captureException } from '@sentry/angular';
import { Currencies, Event, getCurrencySymbol, Invoice, UserProfile } from './';

const getError = (wlp: Partial<WaitListPurchase>) => {
  if (!wlp.errorCode || wlp.status !== WaitListPurchaseStatus.Error) {
    return undefined;
  }
  let error: WaitListPurchaseError;
  switch (wlp.errorCode) {
  // mangopay doc : https://docs.mangopay.com/guide/payins-transactions
  case '001013': // Invalid transaction amount
  case '008006': // Fraud suspected by the bank
  case '008007': // Opposition on bank account (Temporary)
  case '101199': // Transaction refused
    // user should contact bank
    error = WaitListPurchaseError.refusedByBank;
    break;
  case '008504': // Amount of the transaction exceeded the amount permitted
  case '101101': // Transaction refused by the bank (Do not honor)
  case '101102': // Transaction refused by the bank (Amount limit)
  case '101104': // Transaction refused by the bank (card limit reached)
  case '101112': // Maximum amount exceeded
  case '101115': // Debit limit exceeded
    // transaction refusée par la banque : plafonds atteint ou fonds insuffisants
    error = WaitListPurchaseError.unsufficientFunds;
    break;
  case '008002': // Lost card
  case '008003': // Stolen card
  case '101103': // Transaction refused by the terminal
  case '101105': // The card has expired
  case '101106': // The card is inactive
  case '101410': // The card is not active
    // user should change for another card
    error = WaitListPurchaseError.cardDisabled;
    break;
  case '101399':
  case '101301':
  case '101302':
  case '101303':
  case '101304':
  case '101305':
  case '101306':
    // card does not handle 3D secure payments, should change for another card
    error = WaitListPurchaseError.no3DSecure;
    break;
  default:
    /**
       * default handle all the following cases (specific cases which may not happen) + others cases
       * case '101108': // Transaction refused: the Debited Wallet and the Credited Wallet must be different
       * case '101109': // The payment period has expired (bankwire)
       * case '101110': // The payment has been refused (bankwire)
       * case '101111': // Maximum number of attempts reached
       * case '101113': // Maximum Uses Exceeded
       * case '101116': // Debit transaction frequency exceeded
       * case '101118': // An initial transaction with the same card is still pending
       * case '101119': // Invalid or missing bank details
       * case '101025': // The banking alias is not active
       * case '001505': // The PayIn DebitedFunds can't be higher than the PreAuthorization remaining amount
       * case '002996': // Blocked due to User Balance limitations (maximum owned amount reached)
       * case '001999': // Generic Operation error
       */
    // suspicious errors, send alert to reelax for manual check
    error = WaitListPurchaseError.other;
    break;
  }
  return error;
};

export class MinCardDetails {
  public Id: string;
  public Alias: string;
  public ExpirationDate: string;
  public CardProvider: string;
  public Active: boolean;

  public constructor(options: Partial<MinCardDetails>) {
    Object.assign(this, options);
  }
}

export class WLTicketHolder {
  firstname: string;
  lastname: string;
  purchased: boolean;
  customTicketAnswers?: {[fieldId: number]: unknown};

  constructor(options?: any) {
    if (options) {
      this.firstname = options.firstname;
      this.lastname = options.lastname;
      this.purchased = options.purchased;
      this.customTicketAnswers = options.customAnswers;
    }
  }
}

export enum WaitListPurchaseStatus {
  Idle = 'idle',
  Processing = 'processing',
  Error = 'error',
}

export enum WaitListPurchaseError {
  refusedByBank = 'refusedByBank',
  unsufficientFunds = 'unsufficientFunds',
  cardDisabled = 'cardDisabled',
  no3DSecure = 'no3DSecure',
  other = 'other',
}

export const waitlistpurchaseStatusInfos = {
  [WaitListPurchaseStatus.Idle]: {
    identifier: WaitListPurchaseStatus.Idle,
    frenchName: 'Inactif',
  },
  [WaitListPurchaseStatus.Processing]: {
    identifier: WaitListPurchaseStatus.Processing,
    frenchName: 'Transaction en cours',
  },
  [WaitListPurchaseStatus.Error]: {
    identifier: WaitListPurchaseStatus.Error,
    frenchName: 'Erreur',
  },
};

export class WaitListPurchase {
  id?: number;
  allOrNone: boolean;
  sameCategoryOnly: boolean;
  currency: Currencies;
  userInfoId: number;
  debitedAmountWithoutTicket: number;
  debitedWithoutTicket: boolean;
  errorCode: string;
  expirationDate: Date;
  eventId: number;
  paymentToken: string;
  prepaidAmount: number;
  prepaidTickets: number;
  purchasedTickets: number;
  purchaseAutoEnabled: boolean;
  recurringPayinRegistrationId: string;
  ticketHolders: WLTicketHolder[];
  status: WaitListPurchaseStatus;
  CITPayinIds: string;
  error: WaitListPurchaseError;
  lastCardId: string;
  createdAt?: Date;
  updatedAt?: Date;

  maxRegistrationsReached?: boolean; // only retrieved when getting a user waitListPurchase for a given event

  UserProfile?: UserProfile;
  Event?: Event;
  Invoices: Invoice[];

  constructor(data: Partial<WaitListPurchase>) {
    Object.assign(this, data);
    if ((data as any).UserInfo) {
      this.UserProfile = new UserProfile((data as any).UserInfo);
    }
    if (data.ticketHolders && typeof data.ticketHolders === 'object') {
      try {
        this.ticketHolders = Object.values(data.ticketHolders)?.map( (x) => typeof x !=='object' ? new WLTicketHolder(JSON.parse(x as unknown as string)) : x);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ticketholders error : ', error);
        // eslint-disable-next-line no-console
        console.log('ticketholders : ', data.ticketHolders);
        captureException(error);
      }
    }
    this.error = getError(data);
  }

  public get currencySymbol() {
    return getCurrencySymbol(this.currency);
  }

}
