
export enum SeatingInformation {
  Zone = 'zone',
  Gate = 'gate',
  Access = 'access',
  Row = 'row',
  Seat = 'seat',
  Type = 'type',
  DateStart = 'dateStart',
  Other = 'other',
  WeezOrderReference = 'weezOrderReference',
  GrandStand = 'grandStand',
  Section = 'section',
  Floor = 'floor',
}

export const defaultSeatingInformations = Object.values(SeatingInformation)
  .reduce( (acc, s) => ({...acc, [s]: true}), {});
