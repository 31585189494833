import { IFaq } from '../../app/models';


export const faq: IFaq = {
  root: {
    id: 500,
    fragment: 'root',
    question: 'Para facilitar la llegada de los servicios de emergencia, ¿puedes contarnos algo más sobre tu perfil? 👨‍🚒',
    children: ['buyer', 'seller', 'organizer'],
  },
  // Depth = 1
  buyer: {
    id: 501,
    name: 'Comprador(a)',
    fragment: 'acheteur',
    question: 'Mi pregunta se refiere a ...',
    children: ['buyer_event', 'buyingTicket', 'waitingList', 'autoPurchase', 'myAccountBuyer', 'order', 'buyer_aboutReelax'],
  },
  seller: {
    id: 502,
    name: 'Vendedor(a)',
    fragment: 'vendeur',
    question: 'Mi pregunta se refiere a ...',
    children: ['seller_event', 'putOnSale', 'myAccountSeller', 'friendSale', 'payement',  'cancelSelling', 'seller_aboutReelax'],
  },
  organizer: {
    id: 503,
    name: 'Organizador(a)',
    fragment: 'organisateur',
    question: 'Mi pregunta se refiere a ...',
    children: ['settingUpMarketPlace', 'orga_aboutReelax', 'compatibility', 'data'],
  },
  // Depth = 2
  seller_aboutReelax: {
    id: 504,
    name: 'El funcionamiento de Reelax',
    fragment: 'vendeur_fontionnement_reelax',
    children: ['seller_whyReelax'],
  },
  putOnSale: {
    id: 505,
    name: 'Una venta',
    fragment: 'vendeur_mise-en-vente',
    question: 'Y más específicamente ...',
    children: ['sellPrice', 'categoryOrBarcode', 'cashless', 'privateSale', 'saleProcess'],
  },
  cancelSelling: {
    id: 506,
    name: 'Cancelar una venta',
    fragment: 'vendeur_annulation-vente',
    children: ['howToWithdraw', 'ticketNotResale', 'resaleOnMyOwn', 'putOnSaleBySomeone'],
  },
  myAccountSeller: {
    id: 507,
    name: 'Mi cuenta',
    fragment: 'vendeur_mon-compte',
    children: ['cantValidIKYC', 'whyIDCard', 'cantFindConfirmationMail', 'forgotPassword', 'noMoreAccess', 'modifyEmail', 'deleteAccount'],
  },
  friendSale: {
    id: 508,
    name: 'Una venta a un amig@',
    fragment: 'vendeur_vente-ami',
    children: ['howToFriendSale', 'modifyTicketNameSeller'],
  },
  payement: {
    id: 509,
    name: 'Un pago',
    fragment: 'vendeur_paiement',
    children: ['whenReceiveMoney'],
  },
  seller_event: {
    id: 510,
    name: 'Un evento',
    fragment: 'vendeur_event',
    children: ['dontFindEventSeller'],
  },
  buyer_aboutReelax: {
    id: 511,
    name: 'El funcionamiento de Reelax',
    fragment: 'acheteur_fontionnement_reelax',
    children: ['buyer_whyReelax', 'differentPage'],
  },
  buyer_event: {
    id: 512,
    name: 'Un evento',
    fragment: 'acheteur_event',
    children: ['dontFindEventBuyer', 'canceledEvent'],
  },
  buyingTicket: {
    id: 513,
    name: 'La compra de una entrada',
    fragment: 'acheteur_achat-billet',
    children: ['howToBuy', 'searchForATichet', 'whatIsReserved', 'howManyFees', 'howToBuyToFriend'],
  },
  waitingList: {
    id: 514,
    name: 'La lista de espera',
    fragment: 'acheteur_liste-attente-btn',
    children: ['waitingListWorking', 'modifyWaitingList', 'losingPlaceWaitingList', 'knowingMyPosition', 'stepbackInJauge', 'multipleTickets', 'quantityLimit', 'dontReceiveAlerts', 'desinscriptionWaitingList', 'availableTicketsNoAlert'],
  },
  autoPurchase: {
    id: 515,
    name: 'La compra automática',
    fragment: 'acheteur_achat-auto',
    children: ['whyActivateAutomaticPurchase', 'activateDesactivateAutoPurchase', 'cantActivateAutoPurchase', 'modifyCreditCard', 'purchaseOptionFunctioning', 'autoPurchaseEventDate', 'dontReceiveAlertSinceAutoPurchase', 'SMSAlertAutomaticPurchase', 'deleteCardAutomaticPurchase'],
  },
  myAccountBuyer: {
    id: 516,
    name: 'Mi cuenta',
    fragment: 'acheteur_mon-compte',
    children: ['modifyEmailBuyer', 'forgotPasswordBuyer', 'noMoreAccessBuyer', 'deleteAccountBuyer'],
  },
  order: {
    id: 517,
    name: 'Mi orden',
    fragment: 'acheteur_ma-commande',
    children: ['modifyTicketNameBuyer', 'pickupTicket', 'invoiceAfterBuying', 'emailMistakeInPurchase', 'dontReceiveTicket', 'canICancelSale'],
  },
  settingUpMarketPlace: {
    id: 518,
    name: 'La creación de una bolsa de entradas',
    fragment: 'orga_mise-en-place-bob',
    children: ['softwareCompatibility', 'priceSettingUp', 'howSettingUp', 'timeSettingUp', 'reelaxConsultant', 'canIRefundBuyers', 'presenceReelax', 'whoTakesCareSAV'],
  },
  orga_aboutReelax: {
    id: 519,
    name: 'El funcionamiento de Reelax',
    fragment: 'orga_fontionnement_reelax',
    children: ['orga_whyReelax', 'howSecure', 'whyDoingThat', 'whereAreWe'],
  },
  compatibility: {
    id: 520,
    name: 'La compatibilidad',
    fragment: 'orga_compatibilite',
    children: ['softwareCompatibility', 'interferenceDashboard', 'reelaxInTicketingSystem'],
  },
  data: {
    id: 521,
    name: 'Mis datos',
    fragment: 'orga_donnees',
    children: ['integrabilityData', 'whoOwnsData', 'myCgv', 'reelaxProvider'],
  },
  // Depth = 3+
  seller_whyReelax: {
    id: 35,
    fragment: 'vendeur_pourquoi-reelax',
    question: '¿Por qué Reelax Tickets es la plataforma de reventa más segura para comprar y vender tus entradas de segunda mano?',
    score: 0,
    answer: 'Utilizamos <strong>el más alto protocolo de seguridad</strong> para protegerte del fraude en las entradas. En concreto, estamos conectados a bases de datos de venta de entradas para comprobar si las entradas puestas a la venta en nuestra plataforma son válidas. Después, en cuanto se compra una entrada en nuestra plataforma, creamos instantáneamente una <strong>nueva entrada</strong> con <strong>tu nombre</strong>. ',
    children: [],
  },
  sellPrice: {
    id: 522,
    name: 'Un precio de venta',
    children: ['canIChoosePrice', 'priceOfSelling', 'whyPriceDifference', 'howChangePrice'],
  },
  canIChoosePrice: {
    id: 21,
    fragment: 'vendeur_choix-prix',
    question: '¿Puedo definir libremente mi precio de venta?',
    score: 1,
    answer: 'El precio de las entradas no es libre y se rige por dos condiciones:      <br>- La política de precios del organizador, que fija los importes mínimo y máximo a los que se pueden revender las entradas;      <br>- En Francia, por ejemplo, cuando el acontecimiento está subvencionado o ayudado por el Estado, un ayuntamiento, un departamento o una región, la reventa es posible a condición de que el precio de la entrada no sea superior al expuesto.      ',
    children: [],
  },
  priceOfSelling: {
    id: 22,
    fragment: 'vendeur_cout-vente',
    question: '¿Cuánto cuesta poner a la venta mi entrada?',
    score: 1,
    answer: `¡Vender una entrada en Reelax Tickets <span class="fw-700">es gratis</span>!      <br>
<br>La tasa de servicio se añade al precio de la entrada que tú fijes y la paga el comprador. La tasa de servicio es un porcentaje del precio de la entrada y puede variar significativamente en función de los protocolos de seguridad establecidos para asegurar la transacción.      <br>
<br>No se cobrará ningún otro cargo adicional por los servicios de Reelax Tickets.      <br>
<br>Todos los precios se muestran con IVA incluido en los servicios de Reelax Tickets.      <br>
<br>
<br><span class="fw-700">Pregunta relacionada:</span>     <br>- <a href="/faq/vendeur#vendeur_delai-paiement" class="link --primary">¿Cuándo recibiré mi dinero?</a>     `
    ,
    children: [],
  },
  whyPriceDifference: {
    id: 25,
    fragment: 'vendeur_frais-de-service',
    question: '¿Por qué mi entrada no tiene el mismo precio que la que indiqué?',
    score: 1,
    answer: `Cuando vendes una entrada en Reelax Tickets, tienes que indicar la cantidad que quieres recibir en tu cuenta bancaria y nosotros te diremos el precio al que tu entrada estará disponible para la compra.      <br>
<br>Estos dos precios difieren porque añadimos una <strong>comisión bancaria</strong> y nuestro <strong>cargo por servicio</strong> al comprador para garantizar la validez de la entrada adquirida y maximizar tus posibilidades de revender tus entradas.      `,
    children: [],
  },
  howChangePrice: {
    id: 34,
    fragment: 'vendeur_modifier-prix',
    question: '¿Cómo puedo cambiar el precio de mi entrada?',
    score: 0,
    answer: `Desde la pestaña <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>, ve a tu entrada a la venta a través de la sección <strong>Entrada(s) a la venta</strong> y haz clic en <strong>Cambiar el precio</strong>. ¡Puedes cambiar el precio de tu entrada si el organizador del evento lo permite! Una vez que hayas elegido tu precio, haz clic en el botón <strong>Validar</strong>.
<img src="assets/img/faq/esES/vente/18-1-comment-faire-pour-modifier-le-prix-de-mon-billet.png" class="faq-screenshots" />El valor de la izquierda es la cantidad que recibirás y el valor de la derecha es lo que tendrá que pagar el comprador. Una vez que hayas elegido tu precio, haz clic en el botón <strong>Validar</strong>. <img src="assets/img/faq/esES/vente/18-2-comment-faire-pour-modifier-le-prix-de-mon-billet.png" class="faq-screenshots" />`,
    children: [],
  },
  categoryOrBarcode: {
    id: 523,
    name: 'La categoría o el código de barras de mi entrada',
    fragment: 'vendeur_categorie_code_barres',
    children: ['cantPutOnSale', 'dontFindBarcode', 'dontFindCategory', 'culturePassResalable', 'sellAlreadyUsed'],
  },
  cashless: {
    id: 524,
    name: 'El precargado cashless',
    fragment: 'vendeur_cashless',
    children: ['linkCashless'],
  },
  privateSale: {
    id: 525,
    name: 'Mi entrada está en venta privada',
    fragment: 'vendeur_vente-prive',
    children: ['ticketNotVisible'],
  },
  saleProcess: {
    id: 526,
    name: 'El funcionamiento de una venta',
    fragment: 'vendeur_deroulement-vente',
    children: ['howToSell', 'increaseChanceToSell', 'canIResellReelaxTicket', 'sendTicketToBuyer'],
  },
  cantPutOnSale: {
    id: 29,
    fragment: 'vendeur_mise-en-vente-impossible',
    question: '¿Por qué no puedo poner mi entrada a la venta?',
    score: 0,
    answer: `Puede haber varias razones por las que tu entrada no pueda ponerse a la venta.     <br>
<br>Lo más frecuente es un error en el código de barras (el código de barras suele empezar por uno de los siguientes números: 66, 69, 1, 02, 002, 99, 43...).     <br>¡No olvides hacer clic en el punto de <span class="material-icons" translate="no">info</span> para ver ejemplos de códigos de barras!     <br>Te recomendamos que cargues tu entrada para evitar errores. Además, algunas entradas ocultan un número en el código de barras que no es visible en la entrada. <img src="assets/img/faq/esES/vente/13-pourquoi-je-n-arrive-pas-a-mettre-en-vente-mon-billet.png" class="faq-screenshots" />Sin embargo, es posible que algunas categorías no estén permitidas para la reventa o que sólo se permitan cuando el evento esté agotado.     <br>
<br>Por supuesto, si tu entrada es una invitación o ya ha sido reembolsada por el organizador, no podrás revenderla.     <br>
<br>Si poner tu entrada a la venta no funciona, puedes hacer clic en el botón <strong>Solicita ayuda</strong>.
Recibiremos tu entrada y podremos orientarte.
<br>
<br>
<br><span class="fw-700">Preguntas relacionadas:</span>    <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vender una entrada a un amigo?</a>    <br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo pongo mi entrada a la venta?</a>    <br>- <a href="/faq/vendeur#vendeur_revendre-billet-reelax" class="link --primary">¿Puedo revender mi entrada comprada en Reelax Tickets?</a>    <br>`,
    children: [],
  },
  howToSell: {
    id: 16,
    fragment: 'vendeur_comment-vendre',
    question: '¿Cómo pongo mi entrada a la venta?',
    score: 1,
    answer: `1) Selecciona el evento para el que deseas vender una entrada en la pestaña <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Eventos</a>.      <br>
<br>2) Haz clic en <strong>Vender una</strong> entrada. <img src="assets/img/faq/esES/vente/1-1-comment-vendre-mon-billet.png" class="faq-screenshots" />3) Indica la categoría de tu entrada. <img src="assets/img/faq/esES/vente/1-2-comment-vendre-mon-billet.png" class="faq-screenshots" />4) Sube tu entrada o introduce manualmente tu nombre, apellidos y el código de barras de tu entrada (haz clic en el punto de <span class="material-icons" translate="no">info</span> si no encuentras el código de barras). <img src="assets/img/faq/esES/vente/1-3-comment-vendre-mon-billet.png" class="faq-screenshots" /><strong>⚠️ Algunas entradas ocultan un número en el código de barras que no aparece en la entrada. ¡Te recomendamos que utilices el reconocimiento automático para evitar errores!</strong>     <br>
<br>5) Determina y valida el precio de tu entrada.      <br>Siempre puedes cambiar el precio más tarde si lo deseas. <img src="assets/img/faq/esES/vente/1-4-comment-vendre-mon-billet.png" class="faq-screenshots" />6) <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Conéctate</a>, o si no tienes cuenta <a href="/connexion?register=true" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Inscríbete</a> confirmando tu correo electrónico, luego rellena tus datos personales o los datos de la persona que recibirá el dinero si no eres tú. <img src="assets/img/faq/esES/vente/1-5-comment-vendre-mon-billet.png" class="faq-screenshots" />7) Rellena tu documento de identidad y cuenta bancaria o los datos de la persona que recibirá el dinero (este paso puedes hacerlo más tarde si lo deseas) luego valida para completar la venta de tu entrada haciendo clic en Poner a <strong>la venta al público</strong>.      <br>
<br>8) Tu entrada ya está a la venta en la página del evento o en <a href="/faq/vendeur#vendeur_billet-liste-attente" class="link --primary">nuestras listas de espera privadas</a> 🎉 <br>Para multiplicar tus posibilidades de vender tu entrada, comparte el enlace del anuncio de venta en tus redes sociales ;)  <br>
<br>Una vez confirmada la venta, recibirás un correo electrónico de confirmación con un resumen de tu anuncio y un recordatorio del precio de venta de la entrada.      <br>
<br>Una vez que tu entrada esté a la venta, se <strong>podrá comprar en cualquier momento</strong> sin necesidad de confirmación y recibirás una notificación por correo electrónico de que tu entrada se ha vendido.      <br>
<br>Como recordatorio, Reelax Tickets no guarda ningún documento de identidad en su sistema, cada uno de ellos se envía inmediatamente a MANGOPAY, que se encargará de realizar las comprobaciones necesarias de forma segura.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas:</span>    <br>- <a href="/faq/vendeur#vendeur_modifier-prix" class="link --primary">¿Cómo puedo cambiar el precio de mi entrada?</a>    <br>- <a href="/faq/vendeur#vendeur_billet-liste-attente" class="link --primary">¿Por qué mi entrada no es visible para todos?</a>    <br>- <a href="/faq/vendeur#vendeur_delai-paiement" class="link --primary">¿Cuándo recibo mi dinero?</a>     `,
    children: [],
  },
  increaseChanceToSell: {
    id: 28,
    fragment: 'vendeur_augmenter-chances-vente',
    question: '¿Cómo puedo aumentar las posibilidades de vender mi entrada?',
    score: 0,
    answer: `En primer lugar, es importante saber que la mayoría de las entradas se revenden en las dos últimas semanas antes del evento.      <br>
<br>Dicho esto, aquí tienes dos consejos para maximizar tus posibilidades de revender tu entrada:      <br>
<br>1) Cuando vayas a la página <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>, si el organizador ha autorizado la venta a un precio inferior al de compra, puedes cambiar el precio de venta de la entrada haciendo clic en <span class="fw-700">Cambiar el precio</span> de <span class="fw-700">Entrada(s) a la venta</span>.      <br>
<br>2) ¡Comparte el enlace de tu anuncio en tus redes sociales yendo a <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a> (ver captura de pantalla más abajo)!      <br>Para obtener el enlace, haz clic en el botón <span class="fw-700">Compartir mi entrada</span>.      <br><span class="fw-700">Este consejo multiplica por 3 tus posibilidades de revender tu entrada!</span> <img src="assets/img/faq/esES/vente/12-comment-augmenter-les-chances-de-vendre-mon-billet.png" class="faq-screenshots" />3) Por último, tu entrada sigue siendo válida mientras no se haya vendido, por lo que puedes ir al evento con ella o dársela a un familiar.      <br>
<br>Sin embargo, una compra de última hora no es imposible, así que si decides ir al evento o regalar tu entrada, <span class="fw-700">¡debes absolutamente retirar tu entrada de la venta</span> desde la página <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a> o se te denegará el acceso al evento!</span>     <br><span class="fw-700">Esto se debe a que tu entrada se desactivará en cuanto se venda y, por lo tanto, ¡ya no te permitirá acceder al evento!</span>     <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_modifier-prix" class="link --primary">¿Cómo puedo cambiar el precio de mi entrada?</a>     <br>- <a href="/faq/vendeur#vendeur_non-vendu" class="link --primary">Si mi entrada no está vendida, ¿puedo ir al concierto con ella?</a>    <br>- <a href="/faq/vendeur#vendeur_vendu-ailleurs" class="link --primary">¿Qué ocurre si finalmente he vendido yo mismo mi entrada? </a>     `,
    children: [],
  },
  dontFindBarcode: {
    id: 37,
    fragment: 'vendeur_trouver-code-barres',
    question: 'No encuentro mi número de código de barras cuando intento venderlo, ¿qué puedo hacer?',
    score: 0,
    answer: `No te molestes en buscar tu código de barras, ¡nosotros nos encargamos de ello! Todo lo que tienes que hacer es arrastrar y soltar tu entrada en la casilla correspondiente, o hacerle una foto si utilizas un smartphone.
    <br>
    <br>
    <img src="assets/img/faq/esES/vente/37-detection-code-barres.png" class="faq-screenshots" />
    Si el reconocimiento automático no encuentra un código de barras, puedes intentar introducirlo manualmente utilizando el botón <strong>Rellenar mi código de barras manualmente</strong>. Este número suele encontrarse debajo del código de barras o junto al epígrafe "Número de la entrada". El código de barras suele empezar por uno de los siguientes números: 66, 69, 1, 02, 002, 99, 43, etc. No debe confundirse con los números de orden (que a veces empiezan por la letra T).
    <br>
    <br>
    Si sigue sin funcionar, puedes hacer clic en el botón <strong>Solicita ayuda</strong>. Recibiremos tu entrada y podremos orientarte.
<br><span class="fw-700">Preguntas relacionadas:</span>     <br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo pongo mi entrada a la venta?</a>     <br>- <a href="/faq/vendeur#vendeur_mise-en-vente-impossible" class="link --primary">¿Por qué no puedo poner mi entrada a la venta?</a>     <br>`,
    children: [],
  },
  linkCashless: {
    id: 33,
    fragment: 'vendeur_recuperer-cashless',
    question: '¿Qué pasa si he vinculado mi entrada a una cuenta cashless para el evento?',
    score: 0,
    answer: `¡Tu dinero no se <span class="fw-700">transferirá al nuevo titular de la entrada!</span>     <br>
<br>Después del evento, el organizador pondrá a disposición de los espectadores un módulo que te permitirá recuperar el dinero vinculado a tu cuenta cashless introduciendo los datos de tu entrada.      <br>
<br><strong>Esta acción no será automática</strong>, por lo que te recomendamos que compruebes regularmente las redes sociales y/o el sitio web del organizador después del evento para encontrar esta información.      `,
    children: [],
  },
  dontFindCategory: {
    id: 38,
    fragment: 'vendeur_categorie-indisponible',
    question: 'No encuentro mi categoría en la lista, ¿qué debo hacer?',
    score: 0,
    answer: `Algunas categorías pueden no estar autorizadas para la reventa (tarifas reducidas, CE, Culture Pass...).      <br>
<br>
Si aparece un mensaje de error que dice "La reventa no está abierta para entradas de esta categoría", haz clic en el botón <strong>Solicita ayuda</strong> para enviarnos tu entrada. Si tu entrada es revendible, nos pondremos en contacto contigo.
<br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo pongo mi entrada a la venta?</a>     <br>- <a href="/faq/vendeur#vendeur_mise-en-vente-impossible" class="link --primary">¿Por qué no puedo poner mi entrada a la venta?</a>     <br>`,
    children: [],
  },
  culturePassResalable: {
    id: 68,
    fragment: 'vendeur_pass-culture',
    question: '¿Puedo vender mi entrada comprada con el pase cultural?',
    score: 0,
    answer: `
    Lamentablemente, no estamos autorizados a permitir la reventa de entradas adquiridas a través del pase cultural.`,
    children: [],
  },
  sellAlreadyUsed: {
    id: 69,
    fragment: 'vendeur_diviser-pass',
    question: '¿Puedo vender un pase ya utilizado?',
    score: 0,
    answer: `Si tu entrada ya ha sido escaneada, ya no podrás venderla en nuestra plataforma, aunque siga siendo válida para los días siguientes. Las taquillas no nos permiten aceptar reventas de entradas que ya han sido utilizadas.
    <br><br>
    Si deseas acceder al evento un día en concreto y no todos los días como se indica en tu pase. Puedes inscribirte en la lista de espera a través de la página dedicada al evento en Reelax Tickets e intentar obtener una entrada para el día que desees.
    A continuación, una vez obtenida tu nueva entrada, podrás poner a la venta tu primer pase.
      `,
    children: [],
  },
  ticketNotVisible: {
    id: 59,
    fragment: 'vendeur_billet-liste-attente',
    question: '¿Por qué mi entrada no es visible para todos?',
    score: 0,
    answer: `Tu entrada puede reservarse temporalmente (24 horas como máximo) para los usuarios de la lista de espera. Si tu entrada no se vende después de este periodo, estará disponible en la plataforma para que todos los usuarios puedan comprarla.      <br>
<br>Puedes encontrar el enlace para compartirlo en tus redes sociales en tu área <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a> en <strong>Entradas a la venta</strong> o en el correo electrónico de confirmación. Para que te resulte más fácil encontrar este correo electrónico, ten en cuenta que tiene el asunto: <strong>Tu entrada ha sido puesta a la venta</strong>.      <br>
<br>Puedes comprobar en cualquier momento que tu entrada está a la venta desde tu página de <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</span></a> a través de la pestaña <span class="fw-700">Entrada(s) a la venta. <img src="assets/img/faq/esES/vente/3-pourquoi-mon-billet-n-est-pas-visible-par-tout-le-monde.png" class="faq-screenshots" />
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vender una entrada a un amigo?</a>    <br>- <a href="/faq/vendeur#vendeur_delai-paiement" class="link --primary">¿Cuándo recibo mi dinero?</a>    <br>- <a href="/faq/vendeur#vendeur_augmenter-chances-vente" class="link --primary">¿Cómo puedo aumentar las posibilidades de vender mi entrada?</a>     `,
    children: [],
  },
  sendTicketToBuyer: {
    id: 27,
    fragment: 'vendeur_envoi-acheteur',
    question: '¿Tengo que enviar mi entrada al comprador?',
    score: 1,
    answer: `No, ¡no tienes que hacer nada!     <br>
<br>Cuando vendes tu entrada en Reelax Tickets, tu antigua entrada queda invalidada y <strong>el comprador recibe automáticamente una nueva entrada</strong> a su nombre.      <br>
<br>Puedes deshacerte de tu antigua entrada ya que, una vez vendida, el código de barras ha sido desactivado.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vender una entrada a un amigo? </a>     `,
    children: [],
  },
  canIResellReelaxTicket: {
    id: 31,
    fragment: 'vendeur_revendre-billet-reelax',
    question: '¿Puedo revender mi entrada comprada en Reelax Tickets?',
    score: 0,
    answer: `<strong>Sí.</strong>   <br>Puedes utilizar el botón <strong>Revender esta entrada</strong>, disponible en la página <a href="/mon-compte/mes-billets#Achat" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>, en la sección <strong>Entradas compradas</strong>.      <br>
<br>Ten en cuenta que, por motivos de seguridad, podemos limitar el número de reventas por entrada. Si la venta no se realiza correctamente, haz clic en el botón <strong>Solicita ayuda</strong>. Recibiremos tu entrada y podremos orientarte.<br>
<br>
<br><span class="fw-700">Pregunta relacionada :</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo vendo mi entrada?</a>     `,
    children: [],
  },
  howToWithdraw: {
    id: 32,
    fragment: 'vendeur_annuler-vente',
    question: '¿Cómo retiro mi entrada de la venta?',
    score: 0,
    answer: `Puedes anular la venta de tu entrada desde la página <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>, siempre que la entrada en cuestión no esté vendida ni reservada.      <br>
<br>Tras seleccionar la entrada que deseas cancelar, haz clic en <strong>Retirar de la venta</strong> y, a continuación, en <strong>Confirmar</strong>. <img src="assets/img/faq/esES/vente/16-1-comment-retirer-de-la-vente-mon-billet.png" class="faq-screenshots" />
<img src="assets/img/faq/esES/vente/16-2-comment-retirer-de-la-vente-mon-billet.png" class="faq-screenshots" />Como recordatorio, Reelax Tickets no es responsable de la venta de tu entrada y no puede cancelar una venta a un comprador.      `,
    children: [],
  },
  ticketNotResale: {
    id: 19,
    fragment: 'vendeur_non-vendu',
    question: 'Si mi entrada no está agotada, ¿puedo ir al concierto con ella?',
    score: 1,
    answer: `Sí, tu entrada sigue siendo válida si no se ha vendido.      <br>
<br>Si decides ir al evento, <strong>debes retirar absolutamente tu anuncio de venta</strong> yendo a la pestaña <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a> en <span class="fw-700">Entrada(s) a la venta</span> ¡para evitar una mala sorpresa!      <br>
<br>En efecto, si tu entrada ha sido comprada en nuestra plataforma, tu código de barras quedará invalidado y ya no podrás acceder al evento.      <br>
<br>
<br><span class="fw-700">Pregunta relacionada:</span>     <br>- <a href="/faq/vendeur#vendeur_annuler-vente" class="link --primary">¿Cómo puedo retirar mi entrada de la venta?</a>     `,
    children: [],
  },
  resaleOnMyOwn: {
    id: 24,
    fragment: 'vendeur_vendu-ailleurs',
    question: '¿Y si finalmente vendo mi entrada por mi cuenta?',
    score: 0,
    answer: `En caso de que encuentres un comprador por tu cuenta, tendrás que <span class="fw-700">retirar tu entrada</span> de la página de <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a> para evitar que se deniegue la entrada al evento a la persona que compró tu entrada.      <br>
<br>De hecho, si esta misma entrada se compra en nuestra plataforma, tu código de barras quedará invalidado, ¡y se denegará la entrada a la persona que compró tu entrada fuera de la plataforma!      <br>
<br>Como <strong>recordatorio, ¡está estrictamente prohibido revender una entrada que ya haya sido revendida en nuestra plataforma! Es tu deber comprobar y cancelar la venta antes de intentar vender tu entrada por otro medio que no sea nuestra plataforma</strong>.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_annuler-vente" class="link --primary">¿Cómo puedo retirar mi entrada de la venta?</a>     <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vender una entrada a un amigo? </a>     <br>`,
    children: [],
  },
  putOnSaleBySomeone: {
    id: 85,
    fragment: 'vendeur_mis-en-vente-par-autrui',
    question: '¿Qué debo hacer si mi entrada se ha puesto a la venta sin mi autorización?',
    score: 0,
    answer: `
      Dependiendo del origen de tu entrada, si alguien ha puesto a la venta tu entrada con una dirección de correo electrónico diferente a la tuya, puede que hayas recibido un correo electrónico diciendo «Tu entrada acaba de salir a la venta en Reelax Tickets».
      <br>
      <br>
      Este correo electrónico menciona parte del nombre y apellidos de la persona responsable. Si conoces a esta persona, te recomendamos que contactes directamente con ella para resolver la situación.
      <br>
      <br>
      En caso de robo o si no reconoce a la persona en cuestión,
      <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contacte con nosotros</a>.
      <br>
      Entonces podemos intervenir para bloquear la venta.
      `,
    children: [],
  },
  cantValidIKYC: {
    id: 30,
    fragment: 'vendeur_aide-identite',
    question: 'No consigo validar mi identidad, ¿qué debo hacer?',
    score: 0,
    answer: `Algunos problemas son recurrentes al validar los documentos de identidad:      <br>
<br>- El nombre introducido en tu cuenta no es tu nombre de nacimiento,      <br>- La foto enviada está demasiado borrosa o no contiene todo el documento,      <br>- El documento de identidad ya no es válido,      <br>- El documento de identidad no pertenece a un adulto.      <br>
<br>Para enviar tu documento de identidad, te recomendamos los siguientes consejos:      <br>- los documentos deben estar en formato PNG o JPEG,     <br>- los documentos deben ser válidos, legibles, sin cortes y en color     <br>- las fotos tomadas con un smartphone funcionan mejor que los escaneados,      <br>- el tamaño total de los documentos debe ser inferior a 7 MB.   <br>
<br>En caso de que tus documentos sean rechazados, recibirás un correo electrónico indicando que tu documento no ha podido ser validado por nuestro proveedor de pagos y que tienes que volver a iniciar el proceso. Normalmente, el motivo del rechazo se describirá en el mismo correo electrónico, así que tenlo en cuenta cuando vuelvas a enviar tus documentos.      <br>
<br>Si te das cuenta de que alguno de los datos de tu perfil es incorrecto, dirígete a <a href="/mon-compte/informations-personnelles" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Información personal</a> para cambiar tus datos. Si eres menor de edad, puedes rellenar un documento de identidad de un familiar, acordándote de cambiar previamente los datos de tu perfil de Reelax Tickets.      <br>Ten en cuenta que si modificas tu información personal, se anularán los documentos enviados anteriormente y tendrás que enviar un nuevo documento de identidad correspondiente a la nueva información introducida.      <br>
<br>Si sigues teniendo problemas, ponte en contacto con nosotros a través de nuestra página de <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contacto</a>.      <br>
<br>No hay límite de tiempo para validar tu identidad, ya que tu dinero se almacena indefinidamente en una caja fuerte electrónica, que sólo se puede desbloquear con la validación de tu DNI.    <br>
<br>Como recordatorio, Reelax Tickets no guarda ningún documento de identidad en su sistema, sino que cada uno de ellos se envía inmediatamente a MANGOPAY, que se encarga de las comprobaciones necesarias con total seguridad.`,
    children: [],
  },
  whyIDCard: {
    id: 39,
    fragment: 'vendeur_identite',
    question: '¿Por qué tengo que enviar un documento de identidad para la venta? ',
    score: 0,
    answer: `Reelax Tickets es la plataforma de reventa de entradas más segura del mercado, por eso nos asociamos con los organizadores para que todo el mundo pueda revender sus entradas, de forma segura.      <br>
<br>En cuanto a la identificación, de acuerdo con las últimas normativas europeas (AML4 y PSD2) destinadas a proteger a los consumidores y reducir el blanqueo de dinero, nuestro proveedor de pagos MangoPay necesita verificar la identidad de nuestros vendedores. Sin esta verificación, nuestro proveedor de pagos no nos permite enviar el dinero a los vendedores. Ten en cuenta que no guardamos ningún documento: se envían directamente a MangoPay, donde se procesan con total seguridad.      `,
    children: [],
  },
  cantFindConfirmationMail: {
    id: 36,
    fragment: 'vendeur_mail-confirmation',
    question: 'No encuentro el correo electrónico de confirmación de la cuenta, ¿qué debo hacer?',
    score: 0,
    answer: `Si no encuentras el correo electrónico de confirmación (con el asunto <strong>Confirma tu dirección de correo electrónico</strong>), te aconsejamos que compruebes si tu buzón no ha redirigido este correo electrónico en tu sección de Spam (o en la pestaña Promociones para Gmail).      <br>En este caso, ¡asegúrate de indicar que Reelax Tickets no es spam!     <br>De hecho, es posible que tengamos que enviarte correos electrónicos importantes para enviarte tus entradas o notificarte la venta de tus entradas.      <br>
<br>Algunas direcciones de correo electrónico pueden recibir el correo de confirmación con retraso, en cuyo caso te aconsejamos que tengas paciencia. No obstante, si tienes algún problema para validar tu cuenta, ponte en contacto con nosotros a través de la página de <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contacto</a>.      `,
    children: [],
  },
  forgotPassword: {
    id: 40,
    fragment: 'vendeur_mot-de-passe-oublie',
    question: 'He olvidado mi contraseña, ¿qué debo hacer?',
    score: 0,
    answer: 'Si has perdido u olvidado tu contraseña, ve a la página de <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Conexión</span></a> y haz clic en el enlace <span class="fw-700">¿Has olvidado tu contraseña?</span>, que te permitirá crear una nueva contraseña conservando la dirección de correo electrónico que utilizaste para crear tu cuenta.      <br>Después, cuando hagas clic en el botón <span class="fw-700">Confirmar</span>, te enviaremos por correo electrónico un enlace para crear una nueva contraseña.      ',
    children: [],
  },
  forgotPasswordBuyer: {
    id: 81,
    fragment: 'acheteur_mot-de-passe-oublie',
    question: 'He olvidado mi contraseña, ¿qué debo hacer?',
    score: 0,
    answer: 'Si has perdido u olvidado tu contraseña, ve a la página de <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Conexión</span></a> y haz clic en el enlace <span class="fw-700">¿Has olvidado tu contraseña?</span>, que te permitirá crear una nueva contraseña conservando la dirección de correo electrónico que utilizaste para crear tu cuenta.      <br>Después, cuando hagas clic en el botón <span class="fw-700">Confirmar</span>, te enviaremos por correo electrónico un enlace para crear una nueva contraseña.      ',
    children: [],
  },
  modifyEmail: {
    id: 70,
    fragment: 'vendeur_modifier-mail',
    question: '¿Cómo cambio la dirección de correo electrónico de mi cuenta?',
    score: 0,
    answer: `
    Si deseas cambiar la dirección de correo electrónico que estás vinculada a tu cuenta de Reelax Tickets, puedes <a href="/contact" target="_blank" class="link --primary">contactarnos</a> con tu nueva dirección para que podamos realizar el cambio.`,
    children: [],
  },
  modifyEmailBuyer: {
    id: 72,
    fragment: 'vendeur_modifier-mail',
    question: '¿Cómo cambio la dirección de correo electrónico de mi cuenta?',
    score: 0,
    answer: `
    Si deseas cambiar la dirección de correo electrónico que estás vinculada a tu cuenta de Reelax Tickets, puedes <a href="/contact" target="_blank" class="link --primary">contactarnos</a> con tu nueva dirección para que podamos realizar el cambio.`,
    children: [],
  },
  deleteAccount: {
    id: 79,
    fragment: 'vendeur_supprimer-compte',
    question: 'Quiero eliminar mi cuenta. ¿Qué debo hacer?',
    score: 0,
    answer: `
    Por motivos de seguridad, no puedes eliminar tu cuenta directamente desde el área Mi cuenta.
    <br>
    Si deseas eliminar tu cuenta, ponte en contacto con nosotros desde la página <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contacto</a>. Nuestro equipo comprobará tu solicitud y eliminará tu cuenta.
    <br>
    <br>
    <br>
    <span class="fw-700">Pregunta relacionada:</span>
    <br>
    - <a href="/faq/vendeur#vendeur_modifier-mail" class="link --primary">Comment modifier l'adresse mail de mon compte ?</a>
    `,
    children: [],
  },
  deleteAccountBuyer: {
    id: 80,
    fragment: 'acheteur_supprimer-compte',
    question: 'Quiero eliminar mi cuenta. ¿Qué debo hacer?',
    score: 0,
    answer: `
    Por motivos de seguridad, no puedes eliminar tu cuenta directamente desde el área Mi cuenta.
    <br>
    Si deseas eliminar tu cuenta, ponte en contacto con nosotros desde la página <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contacto</a>. Nuestro equipo comprobará tu solicitud y eliminará tu cuenta.
    <br>
    <br>
    <br>
    <span class="fw-700">Pregunta relacionada:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_modifier-mail" class="link --primary">Comment modifier l'adresse mail de mon compte ?</a>
    `,
    children: [],
  },
  noMoreAccess: {
    id: 82,
    fragment: 'vendeur_plus-acces',
    question: 'Ya no puedo acceder a mi cuenta. ¿Qué debo hacer?',
    score: 0,
    answer: `
    Si ya no puedes acceder a tu cuenta, es posible que se haya bloqueado debido a demasiados intentos de inicio de sesión. En este caso, deberías haber recibido un correo electrónico «Tu cuenta de Reelax Tickets está bloqueada» que contiene un enlace para desbloquear tu cuenta.
    <br>
    <br>
    Si no ha recibido un correo electrónico, intente cambiar su contraseña. La función «¿Has olvidado tu contraseña?» está disponible en la página de 
    <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>inicio de sesión</a>.
    <br>
    <br>
    Si el problema persiste, es posible que tu dirección de correo electrónico haya sido bloqueada. En ese caso, puedes
    <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contacte con nosotros</a>
    para que se la desbloqueemos.
    <br>
    <br>
    <br>
    <span class="fw-700">Preguntas relacionadas:</span>
    <br>
    - <a href="/faq/vendeur#vendeur_modifier-mail" class="link --primary">Comment modifier l'adresse mail de mon compte ?</a>
    <br>
    - <a href="/faq/vendeur#vendeur_mot-de-passe-oublie" class="link --primary">J’ai oublié mon mot de passe. Que faire ?</a>
    `,
    children: [],
  },
  noMoreAccessBuyer: {
    id: 83,
    fragment: 'acheteur_plus-acces',
    question: 'Ya no puedo acceder a mi cuenta. ¿Qué debo hacer?',
    score: 0,
    answer: `
    Si ya no puedes acceder a tu cuenta, es posible que se haya bloqueado debido a demasiados intentos de inicio de sesión. En este caso, deberías haber recibido un correo electrónico «Tu cuenta de Reelax Tickets está bloqueada» que contiene un enlace para desbloquear tu cuenta.
    <br>
    <br>
    Si no ha recibido un correo electrónico, intente cambiar su contraseña. La función «¿Has olvidado tu contraseña?» está disponible en la página de 
    <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>inicio de sesión</a>.
    <br>
    <br>
    Si el problema persiste, es posible que tu dirección de correo electrónico haya sido bloqueada. En ese caso, puedes
    <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contacte con nosotros</a>
    para que se la desbloqueemos.
    <br>
    <br>
    <br>
    <span class="fw-700">Preguntas relacionadas:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_modifier-mail" class="link --primary">Comment modifier l'adresse mail de mon compte ?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_mot-de-passe-oublie" class="link --primary">J’ai oublié mon mot de passe. Que faire ?</a>
    `,
    children: [],
  },
  howToFriendSale: {
    id: 23,
    fragment: 'vendeur_vente-privee',
    question: '¿Cómo vender una entrada a un amigo?',
    score: 0,
    answer: `Cuando pongas tu entrada a la venta, tienes que marcar la casilla <span class="fw-700">Habilitar la reventa privada sólo para un amigo</span> en el paso 2 (debajo de la opción de precio). Cuando finalice la venta, tendrás que compartir el enlace de la entrada con tu amigo para que pueda comprarla. <img src="assets/img/faq/esES/vente/8-1-comment-vendre-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />Si encuentras un comprador después de que tu entrada se haya puesto a la venta, puedes obtener un enlace privado desde tu Cuenta en la pestaña <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>. Simplemente ve a la entrada en cuestión, y luego haz clic en <span class="fw-700">Poner en venta a un amigo sólo</span>. Con esta función, tu entrada ya no estará a la venta para el público en general. <img src="assets/img/faq/esES/vente/8-2-comment-vendre-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />Y luego haz clic en <strong>Compartir mi entrada</strong> para obtener el enlace privado para compartir.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_augmenter-chances-vente" class="link --primary">¿Cómo puedo aumentar las posibilidades de vender mi entrada?</a>     <br>- <a href="/faq/vendeur#vendeur_modifier-prix" class="link --primary">¿Cómo puedo cambiar el precio de mi entrada?</a>    <br>`,
    children: [],
  },
  modifyTicketNameBuyer: {
    id: 12,
    fragment: 'acheteur_modifier-nom-billet',
    question: '¿Cómo cambio el nombre de mi entrada?',
    score: 0,
    answer: `Puedes utilizar la función de reventa a un amigo comprando tu propia entrada a través de una segunda cuenta de Reelax Tickets.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas:</span>     <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vender una entrada a un amigo?</a>     <br>- <a href="/faq/acheteur#acheteur_alertes-mails" class="link --primary">Ya no recibo alertas por correo electrónico, ¿me toca a mí? </a>   `,
    children: [],
  },
  modifyTicketNameSeller: {
    id: 74,
    fragment: 'vendeur_modifier-nom-billet',
    question: '¿Cómo cambio el nombre de mi entrada?',
    score: 0,
    answer: `Puedes utilizar la función de reventa a un amigo comprando tu propia entrada a través de una segunda cuenta de Reelax Tickets.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas:</span>     <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vender una entrada a un amigo?</a>     <br>- <a href="/faq/acheteur#acheteur_alertes-mails" class="link --primary">Ya no recibo alertas por correo electrónico, ¿me toca a mí? </a>   `,
    children: [],
  },
  whenReceiveMoney: {
    id: 20,
    fragment: 'vendeur_delai-paiement',
    question: '¿Cuándo recibo mi dinero?',
    score: 1,
    answer: `
    El plazo de pago se te comunicará <span class="fw-700">antes de confirmar la venta</span>.
    <br>
    <br>
    Para que podamos enviarte tu transferencia, deberás rellenar tu <span class="fw-700">IBAN</span> y tu <span class="fw-700">documento de identificación</span> en <a href="/mon-compte/compte-bancaire" target="_blank "class="link --primary"><span class="material-icons" translate="no">launch</span>Cuenta-bancaria</a>. La transferencia sólo podrá realizarse una vez que tu ID haya sido validado por nuestro proveedor de servicios de pago (Mangopay). Mientras tanto, tu dinero se guardará en una caja fuerte electrónica. Esta caja fuerte sólo podrá desbloquearse una vez validado tu documento de identidad.
    <br><br>
    Dependiendo del evento y del tipo de entrada, el pago puede efectuarse en un plazo de <span class="fw-700">5 días laborables después de la venta</span>, o en un plazo de <span class="fw-700">8 días laborables una vez que haya pasado el evento. </span> Puede hacer un seguimiento de tu pago, directamente desde tu <a href="/mon-compte/compte-bancaire" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Cuenta</a>. 
    <br><br>
    La transferencia a tu cuenta bancaria se realizará a nombre de Mangopay, Mgp*Reelax Tick o reelax tick.<br>
<br><span class="fw-700">Pregunta relacionada :</span><br>- <a href="/faq/vendeur#vendeur_aide-identite" class="link --primary">No consigo validar mi identidad, ¿qué debo hacer?</a>     <br>`,
    children: [],
  },
  dontFindEventBuyer: {
    id: 26,
    fragment: 'acheteur_evenement-indisponible',
    question: '¿Por qué no encuentro el evento en la lista?',
    score: 1,
    answer: 'Para garantizar la validez de las entradas canjeadas en la plataforma, <strong>sólo</strong> ofrecemos la reventa <strong>para los eventos de nuestros socios</strong>.      <br>Si el evento no aparece en <a href="/evenements" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Eventos</a>, <a href="/contact" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>ponte en contacto con nosotros</a> e indícanos en qué evento estás interesado. Nuestro equipo se pondrá en contacto con el organizador en cuestión.     </a><br>También puedes enviar un mensaje al organizador pidiéndole que active nuestro servicio.      ',
    children: [],
  },
  dontFindEventSeller: {
    id: 73,
    fragment: 'vendeur_evenement-indisponible',
    question: '¿Por qué no encuentro el evento en la lista?',
    score: 1,
    answer: 'Para garantizar la validez de las entradas canjeadas en la plataforma, <strong>sólo</strong> ofrecemos la reventa <strong>para los eventos de nuestros socios</strong>.      <br>Si el evento no aparece en <a href="/evenements" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Eventos</a>, <a href="/contact" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>ponte en contacto con nosotros</a> e indícanos en qué evento estás interesado. Nuestro equipo se pondrá en contacto con el organizador en cuestión.     </a><br>También puedes enviar un mensaje al organizador pidiéndole que active nuestro servicio.      ',
    children: [],
  },
  canceledEvent: {
    id: 66,
    fragment: 'acheteur_evenement-annule-reporte',
    question: '¿Qué ocurre si el acto se cancela o se aplaza?',
    score: 0,
    answer: `En caso de aplazamiento o cancelación del evento, tendrás la posibilidad de recibir un reembolso a través del organizador del evento o directamente a través de Reelax Tickets. <strong>Las condiciones del reembolso se te especificarán antes de la confirmación de tu pedido</strong>.      <br>Aparte de la cancelación o el aplazamiento del evento, de conformidad con el artículo L.121-28 del Código de Consumo francés, la compra de entradas en Reelax Tickets no puede ser objeto de un derecho de retractación. La entrada no es canjeable ni reembolsable.      <br>No obstante, si ya no te interesa tu entrada, puedes revenderla fácilmente en nuestro sitio web.    <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo pongo a la venta mi entrada?</a>     <br>- <a href="/faq/acheteur#acheteur_annuler-achat" class="link --primary">Quiero anular mi compra, ¿me devuelven el dinero? </a>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     `,
    children: [],
  },
  howToBuy: {
    id: 0,
    fragment: 'acheteur_comment-acheter',
    question: '¿Cómo comprar una entrada?',
    score: 1,
    answer: `
    1) Ve a <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Eventos</a>     <br>
  <br>
  2) Haz clic en el evento de tu elección.      <br>
<br>3) Haz clic en el botón <span class="fw-700">Comprar una</span> entrada. <img src="assets/img/faq/esES/achat/1-1-comment-acheter-un-billet.png" class="faq-screenshots" />4) Haz clic en la categoría de entradas que te interese. <img src="assets/img/faq/esES/achat/1-2-comment-acheter-un-billet.png" class="faq-screenshots" />5) Selecciona la entrada que te interese y haz clic en el botón <span class="fw-700">Comprar</span>. <img src="assets/img/faq/esES/achat/1-3-comment-acheter-un-billet.png" class="faq-screenshots" />6) Si quieres ver los detalles del precio, puedes hacer clic en el punto de información <span class="material-icons" translate="no">info</span>. A continuación, puedes hacer clic en <strong>Siguiente</strong>. <img src="assets/img/faq/esES/achat/1-4-comment-acheter-un-billet.png" class="faq-screenshots" />7) Rellena los campos con los datos del comprador y del titular de la entrada y, a continuación, haz clic en <span class="fw-700">Siguiente</span>. <img src="assets/img/faq/esES/achat/1-5-comment-acheter-un-billet.png" class="faq-screenshots" />8) Rellena los datos de tu tarjeta de crédito y espera a que se valide el pago 3D Secure de tu banco que aparece automáticamente en la página. A continuación, volverás automáticamente a la página de entradas de Reelax.      <br>
<br>9) A continuación, podrás descargar tu entrada en la página de confirmación de la compra o directamente en el correo electrónico enviado.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_evenement-indisponible" class="link --primary">¿Por qué no encuentro el evento en la lista? </a>     <br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a> (Útil si no hay entradas disponibles para la categoría que buscas)      <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>     `,
    children: [],
  },
  searchForATichet: {
    id: 78,
    question: 'Busco una entrada. ¿Qué puedo hacer?',
    fragment: 'acheteur_cherche-billet',
    score: 1,
    answer: `
      En la página del evento en cuestión, puedes hacer clic en <strong>Comprar una entrada</strong>, y haz clic en <strong>Únete a la lista de espera</strong>. 
      De este modo, recibirás un aviso cuando salgan a la venta nuevas entradas.
      <br>
      <br>
      Inscribirse en la lista de espera es la única manera de estar informado de la disponibilidad. Ponerte en contacto directamente con nosotros no te darás más posibilidades de obtener una entrada.
      <br>
      <br>
      <br>
      <span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_evenement-indisponible" class="link --primary">¿Por qué no encuentro el evento en la lista? </a>     <br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a> (Útil si no hay entradas disponibles para la categoría que buscas)      <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>
      `,
    children: [],
  },
  whatIsReserved: {
    id: 10,
    fragment: 'acheteur_billet-reserve',
    question: '¿Qué significa "Reservada" en algunas entradas?',
    score: 1,
    answer: `Cuando una entrada de la lista de entradas disponibles está marcada como <strong>Reservada</strong> y no va seguida del botón Comprar, significa que la entrada está en la cesta de la compra de otro usuario y que éste está en proceso de finalizar su compra.      <br>
<br>Una entrada puede permanecer en el carrito de la compra un máximo de 10 minutos, y si no se realiza el pago en 10 minutos, volverá a estar disponible.      <br>
<br>Algunas entradas pueden reservarse antes de que estén disponibles en la página de compra pública, como las entradas para vender a un amigo o las entradas para personas en lista de espera. <img src="assets/img/faq/esES/achat/9-que-signifie-reserve-sur-certains-billets.png" class="faq-screenshots" />
<br><span class="fw-700">Preguntas relacionadas:</span>     <br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>     `,
    children: [],
  },
  howManyFees: {
    id: 18,
    fragment: 'acheteur_frais-de-service',
    question: '¿A cuánto ascienden los gastos por la compra de una entrada? ',
    score: 0,
    answer: `Nuestra tarifa de servicio se añade al precio de la entrada y es un porcentaje del precio de la entrada.      <br>
<br>Estas tarifas pueden variar significativamente en función de los <strong>protocolos de seguridad</strong> establecidos para garantizar las transacciones.      <br>
<br>Puedes acceder a los detalles del precio en cualquier momento antes de la confirmación del pedido haciendo clic en el botón <strong>Comprar</strong> de la entrada que elijas y, a continuación, en el icono <span class="material-icons" translate="no">info</span>(ver captura de pantalla a continuación): <img src="assets/img/faq/esES/achat/17-1-a-combien-s-elevent-les-frais-de-service-pour-l-achat-d-un-billet.png" class="faq-screenshots" />

<img src="assets/img/faq/esES/achat/17-2-a-combien-s-elevent-les-frais-de-service-pour-l-achat-d-un-billet.png" class="faq-screenshots" />No se cobrará ninguna otra tarifa adicional en los servicios de Reelax Tickets.      <br>Todos los precios se muestran con IVA incluido en los servicios de Reelax Tickets.      <br>
<br>
<br><span class="fw-700">Pregunta relacionada:</span>     <br>- <a href="/faq/acheteur#acheteur_pourquoi-reelax" class="link --primary">¿Por qué Reelax Tickets es la plataforma de reventa más segura para comprar y vender tus entradas de segunda mano?</a>    <br>`,
    children: [],
  },
  howToBuyToFriend: {
    id: 16,
    fragment: 'acheteur_achat-prive',
    question: '¿Cómo puedo comprar una entrada a un amigo?',
    score: 0,
    answer: `
    Cuando la entrada se ponga a la venta, tu amigo deberá marcar la casilla <strong>Habilitar la reventa privada sólo para un amigo</strong> en el paso 2 (debajo de la opción de precio). Al final de la venta, él/ella tendrá que compartir contigo el enlace privado de la entrada que tú podrás comprar. <img src="assets/img/faq/esES/achat/15-comment-acheter-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />
    <br><br>
    No hace falta que des tu dirección de correo electrónico al vendedor, si te la piden lo más probable es que sea una estafa. 
    <br><br>
    Esté atento, personas malintencionadas pueden enviarte direcciones falsas desde nuestro sitio Web. Compruebes sistemáticamente la <span class="fw-700">dirección del sitio</span> (URL) y <span class="fw-700">no comparta tu correo electrónico con personas en las redes sociales</span>. Nuestras URL empiezan por https://reelax-tickets.com/ . Diferentes sitios web son fraudulentos (por ejemplo, "https://reelax-tickets.com.de/"). En caso de duda, ponte en contacto con nosotros.
    <br><br><br><span class="fw-700">Preguntas relacionadas :</span><br>
    - <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">¿Cómo vendo una entrada a un amigo?</a>     <br>
    - <a href="/faq/acheteur#acheteur_modifier-nom-billet" class="link --primary">¿Cómo puedo cambiar el nombre de mi billete? </a>     <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Busco varias entradas. Reduce esto mis posibilidades de conseguir entradas?</a>     `,
    children: [],
  },
  waitingListWorking: {
    id: 1,
    fragment: 'acheteur_liste-attente',
    question: '¿Cómo funciona la lista de espera?',
    score: 1,
    answer: `¿Quieres comprar una entrada para uno de nuestros eventos, pero no hay entradas disponibles?      <br>Reelax, es normal, las entradas suelen agotarse muy rápidamente.      <br>
<br>Cuando no hay entradas disponibles, tienes que inscribirte en la lista de espera específica. En cuanto una entrada sale a la venta, enviamos un correo electrónico con un enlace para pedir la entrada a un pequeño grupo de personas. Este enlace es privado durante un tiempo determinado y permite a una persona del grupo reservar la entrada.      <br>El grupo se selecciona por orden cronológico o aleatorio.      <br>
<br>Para unirte a una lista de espera, ve a la página de Entradas Reelax dedicada al evento y haz clic en el botón <span class="fw-700">Comprar una entrada</span> y luego en el botón <span class="fw-700">Únete a la lista de espera</span> para unirte a la lista de espera. <img src="assets/img/faq/esES/achat/2-1-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
<img src="assets/img/faq/esES/achat/2-2-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />A continuación, selecciona las categorías que te interesan. <img src="assets/img/faq/esES/achat/2-3-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />En el paso 2, tendrás que iniciar sesión (o registrarte confirmando tu correo electrónico si no tienes cuenta) y rellenar tus datos personales.      <br>
<br>En el paso 3, valídate y elige el precio máximo y la cantidad de entradas que deseas. <img src="assets/img/faq/esES/achat/2-4-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />Por último, al validarte, quedarás inscrito en la lista de espera, y recibirás correos electrónicos en cuanto sea tu turno y una entrada corresponda a tus expectativas.      <br>
<br>Para la mayoría de los eventos, también es posible activar la compra automática. Esta función te permite comprar automáticamente tu entrada en lugar de recibir un aviso por correo electrónico. Esta opción opcional no garantiza que obtengas una entrada, pero te evitará tener que comprobar constantemente tu correo electrónico.      <br>Si no has activado la compra automática, una vez que recibas el correo electrónico indicando que hay una entrada disponible, tendrás que ser rápido para asegurarte de que la consigues (normalmente recibirás entre 10 y 50 correos electrónicos).      <br>
<br>Para ello, lo único que tienes que hacer es pulsar el botón <strong>Obtener</strong> entrada que aparece en el correo electrónico! <img src="assets/img/faq/esES/achat/2-5-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" /><strong>No te preocupes, si se te pasa una entrada, es más que probable que recibas futuras alertas hasta que hayas podido comprar el número de entradas que desees!</strong>     <br>
<br>Por último, si deseas modificar tu inscripción (categoría, número de entradas solicitadas, compra automática...), puedes modificar tu inscripción desde la página <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a>. A continuación, haz clic en el botón <strong>Modificar mi inscripción</strong> para realizar los cambios.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>
- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>      <br>
- <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo modificar mi inscripción en la lista de espera ?</a>     <br>
- <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Busco varias entradas, ¿esto reduce mis posibilidades de conseguir entradas?</a>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     `,
    children: [],
  },
  modifyWaitingList: {
    id: 4,
    fragment: 'acheteur_modification-inscription',
    question: '¿Puedo cambiar mi inscripción en la lista de espera?',
    score: 1,
    answer: `Sí, puedes modificar tu inscripción (cambiar el número de entradas que deseas, activar o desactivar la compra automática, darte de baja...) en una lista de espera en cualquier momento desde la página <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mi cuenta</a>.      <br><strong>Tu plaza inicial sigue siendo la misma</strong>, aunque hagas cambios (añadir nuevas tarifas, cambiar el precio, cambiar el número de entradas que quieres, activar la compra automática...) en tu inscripción.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>     `,
    children: [],
  },
  losingPlaceWaitingList: {
    id: 5,
    fragment: 'acheteur_modification-rang',
    question: 'Si cambio mi inscripción, ¿pierdo mi plaza en la lista de espera? ',
    score: 1,
    answer: `No! <strong>Tu plaza inicial sigue siendo la misma</strong>, aunque hagas cambios (añadir nuevas tarifas, cambiar el precio, cambiar el número de entradas que quieres, activar la compra automática...) en tu inscripción.      <br>Del mismo modo, si te das de baja y vuelves a activar tu inscripción más tarde, recuperarás tu plaza inicial.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo cambiar mi inscripción en la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Busco más de una entrada, ¿esto reduce mis posibilidades de conseguir entradas?</a>     <br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>     `,

    children: [],
  },
  knowingMyPosition: {
    id: 2,
    fragment: 'acheteur_rang',
    question: '¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?',
    score: 1,
    answer: `
    Ahora puedes ver tu rango más claramente en la página.
    <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a>.
    <br>
    <img src="assets/img/faq/esES/achat/3-classement-liste-d-attente.png" class="faq-screenshots" />
    El cursor corresponde a tu posición y tus posibilidades de obtener entradas serán óptimas cuando el indicador de color pase por tu nombre de pila.
    <br>
    <br>
    Para tu información, así es como funciona la lista de espera: en cuanto se pone a la venta una entrada se pone a la venta, nuestro sistema envía una alerta por correo electrónico con un enlace privado para comprar la entrada a un pequeño grupo de personas que se seleccionarán por orden cronológico (90% para la mayoría de los eventos) o al azar (10%).
    <br><br><br>
    <span class="fw-700">Preguntas relacionadas :</span>
    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo cambiar mi inscripción en la lista de espera?</a>
    `,
    children: [],
  },
  stepbackInJauge: {
    id: 3,
    fragment: 'acheteur_jauge_reculer',
    question: '¿Por qué siento que retrocedo en el indicador?',
    score: 1,
    answer: `
    Si tienes la impresión de que avanzas a un ritmo más lento o de que tu porcentaje disminuye, es normal, ya que las inscripciones en la lista de espera aumentan a medida que se acerca el evento. 
    <br>
    <br>
    Pero no te preocupes, esto no afecta a tu clasificación en la lista de espera: tu clasificación sigue siendo la misma.
    <br>
    <br>
    Para tu información, así es como funciona la lista de espera: tan pronto como una entrada sale a la venta, nuestro sistema envía una alerta por correo electrónico con un enlace privado para comprar la entrada a un pequeño grupo de personas que serán seleccionadas por orden cronológico (90% para la mayoría de los eventos) o al azar (10%).
    <br>
    <br>
    <br>
    <span class="fw-700">Preguntas relacionadas :</span>
    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo cambiar mi inscripción en la lista de espera?</a>
    `,
    children: [],
  },
  multipleTickets: {
    id: 6,
    fragment: 'acheteur_chances-billets',
    question: 'Busco varias entradas, ¿esto reduce mis posibilidades de conseguir entradas? ',
    score: 1,
    answer: `<strong>No</strong>, el número de entradas buscadas no influye en la asignación de alertas. Todas las alertas contienen una sola entrada. Si buscas más de una entrada, recibirás alertas hasta que hayas obtenido el número de entradas deseado.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_modification-rang" class="link --primary">Si cambio mi inscripción, ¿pierdo mi puesto en la lista de espera? </a>     <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     <br>
- <a href="/faq/acheteur#acheteur_options-achat-auto" class="link --primary">¿Cómo funcionan las opciones de compra cuando se activa la compra automática?</a>
`,
    children: [],
  },
  quantityLimit: {
    id: 84,
    fragment: 'acheteur_quantite-limitee',
    question: '¿Por qué no puedo conseguir todas las entradas que quiera a través de la lista de espera?',
    score: 1,
    answer: `
    Cada inscripción en la lista de espera da derecho a un número limitado de entradas en todas las categorías. Este límite puede variar en función del evento.
    <br>
    <br>
    El número máximo de entradas que puede solicitar se indica al inscribirse en la lista de espera.
    <br>
    <br>
    Este límite da a todos la oportunidad de conseguir entradas.
    <br>
    <br>
    <br>
    <span class="fw-700">Preguntas relacionadas :</span><br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo modificar mi inscripción en la lista de espera ?</a>     <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Busco varias entradas, ¿esto reduce mis posibilidades de conseguir entradas?</a>
    `,
    children: [],
  },
  purchaseOptionFunctioning: {
    id: 67,
    fragment: 'acheteur_options-achat-auto',
    question: '¿Cómo funcionan las opciones de compra cuando se activa la compra automática?',
    score: 0,
    answer: `En el paso 3 al inscribirte en la lista de espera, cuando actives la compra automática, se te ofrecerán dos opciones si buscas varias entradas: 
    <br><br>
    - Sólo quiero ir al evento si consigo 2 entradas al mismo tiempo.
    <br>
    - Sólo quiero entradas de la misma categoría.
    
    <br><br>
    <img src="assets/img/faq/esES/achat/8-1Comment-fonctionnent-les-choix-d’options-lorsqu’on-active-l’achat-automatique.png" class="faq-screenshots" />

    Para tu información, estas opciones pueden reducir tus posibilidades de ser seleccionado/a, pero pueden ayudarte a afinar tu búsqueda. :)<br>
Puedes activar ambas opciones al mismo tiempo, o desactivarlas individualmente desde tu cuenta.
<br><br><br>
<span class="fw-700">Preguntas relacionadas :</span>
<br>
- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>
<br>
- <a href="/faq/acheteur#acheteur_modification-rang" class="link --primary">Si cambio mi inscripción, ¿pierdo mi plaza en la lista de espera?</a>
<br>
- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>
<br>
- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la compra automática?</a>
<br>     
 - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Busco varias entradas, ¿esto reduce mis posibilidades de conseguir entradas?</a>
    `,
    children: [],
  },
  dontReceiveAlerts: {
    id: 13,
    fragment: 'acheteur_alertes-mails',
    question: 'Ya no recibo alertas por correo electrónico, ¿me toca a mí? ',
    score: 0,
    answer: `No, ¡no significa que haya pasado tu turno!      <br>Seguiremos enviándote alertas mientras estés registrado y no recibas todas las entradas que solicitaste.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>      <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>     `,
    children: [],
  },
  desinscriptionWaitingList: {
    id: 64,
    fragment: 'acheteur_desinscription',
    question: '¿Cómo me doy de baja de la lista de espera? ',
    score: 0,
    answer: `Si deseas darte de baja de una lista de espera, puedes ir a <a href="/mon-compte/mes-listes-d-attente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a> y hacer clic en el botón <strong>Cancelar la suscripción a todas las categorías</strong>. Si deseas darte de baja sólo de determinadas categorías, tendrás que hacer clic en el botón <strong>Modificar mi registro</strong> para aplicar los cambios en detalle.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_annuler-achat" class="link --primary">Quiero cancelar mi compra, ¿me devuelven el dinero? </a>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     `,
    children: [],
  },
  availableTicketsNoAlert: {
    id: 71,
    fragment: 'acheteur_pas-alertes',
    question: '¿Por qué hay entradas disponibles en la página del evento, pero no he recibido ninguna alerta a pesar de estar en la lista de espera?',
    score: 0,
    answer: `
    En cuanto una entrada sale a la venta, enviamos un correo electrónico con un enlace que permite a un pequeño grupo de personas pedir la entrada por orden cronológico (90% de probabilidades) o al azar (10% de probabilidades). Si la entrada no se pide en 24 horas, pasa a estar disponible en la página pública y cualquiera puede pedirla.
    <br>
    <br>
Si no has recibido ninguna alerta que te avise de que hay una entrada disponible, es porque tu viaje aún no ha llegado, pero sigue teniendo prioridad para recibir alertas de entradas disponibles al estar inscrito en la lista de espera. 
Puedes obtener más información sobre tu clasificación aquí: <a href="/mon-compte/mes-listes-d-attente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a>    
      `,
    children: [],
  },
  whyActivateAutomaticPurchase: {
    id: 77,
    fragment: 'acheteur_pourquoi-achat-automatique',
    question: '¿Por qué activar la compra automática?',
    score: 1,
    answer: `
    La función de compra automática es <span class="fw-700">gratuita</span>, por lo que <span class="fw-700">no tienes que estar día y noche comprobando tus notificaciones</span> en busca de un billete. Cuando llega tu turno, el billete se compra directamente. 
    <br><br>
Para activarla, basta con que realices <span class="fw-700">la autenticación 3D Secure</span>, lo que nos permitirá cargarte <span class="fw-700">el importe que hayas autorizado</span> cuando llegue tu recorrido. 
<br><br>
Cuando actives esta función, podrás gestionar los parámetros establecidos, en cualquier momento, desde tu <span class="fw-700">Cuenta</span>, incluyendo : <br>
- La <span class="fw-700">fecha límite de adeudo</span>; <br>
- La <span class="fw-700">eliminación de la tarjeta bancaria</span> introducida;<br>
- La <span class="fw-700">modificación de la tarjeta bancaria</span> ; <br>
- Y <span class="fw-700">desactivar</span> la función en cualquier momento. <br>
<br>
Los datos bancarios no se almacenan en Reelax Tickets: se transmiten directamente a MangoPay y se procesan con total <span class="fw-700">seguridad</span>.
<br><br>
El importe máximo que autorice debe estar <span class="fw-700">disponible</span> en tu tarjeta bancaria. No se congela ningún importe en tu cuenta bancaria. Si la transacción no tiene éxito, se desactivará la compra automática. 
<br><br>
Como recordatorio, tu plaza inicial sigue siendo la misma, aunque realices cambios (añadir nuevas tarifas, modificar el precio, el número de billetes necesarios, activar la compra automática, etc.) en tu inscripción. 
<br><br><br>
<span class="fw-700">Preguntas relacionadas :</span><br>
- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a><br>
- <a href="/faq/acheteur#acheteur_modifier-achat-automatique" class="link --primary">¿Cómo cambiar la tarjeta de crédito utilizada para la compra automática?</a><br>
- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a><br>
`,
    children: [],
  },
  activateDesactivateAutoPurchase: {
    id: 7,
    fragment: 'acheteur_achat-automatique',
    question: '¿Cómo activo/desactivo la compra automática?',
    score: 1,
    answer: `En la mayoría de los eventos, en lugar de recibir avisos por correo electrónico, ¡puedes comprar <strong>automáticamente</strong> tu entrada! Esta función opcional no garantiza que consigas entradas, pero te ahorrará la molestia de controlar tus notificaciones día y noche.      <br>
<br>Tras inscribirte en la lista de espera que elijas, haz clic en <strong>Modificar mi registro</strong> y ve al <strong>paso 3</strong>. <img src="assets/img/faq/esES/achat/7-1-comment-activer-desactiver-l-achat-automatique.png" class="faq-screenshots" />. A continuación, puedes activar la compra automática y <strong>definir la fecha límite tras la cual no deseas que se te siga cargando</strong>.      <br>
<br>A continuación, introduce los datos del titular de la entrada y después tus datos bancarios. <img src="assets/img/faq/esES/achat/7-2-comment-activer-desactiver-l-achat-automatique.png" class="faq-screenshots" />⚠️ <strong>Ten en cuenta que tu método de pago debe ser una tarjeta Visa o Mastercard y debe ser apta para la autenticación 3D Secure. </strong>Después, haz clic en el botón <strong>Confirmar</strong> para validar la activación de la compra automática.      <br>Una vez activada la compra automática, <strong>recibirás un correo electrónico con un resumen.</strong> <img src="assets/img/faq/esES/achat/7-3-comment-activer-desactiver-l-achat-automatique.png?v=2" class="faq-screenshots" />Si necesitas cambiar tu forma de pago para la compra automática, puedes hacerlo directamente en <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a>, haciendo clic en el botón <strong>"Cambiar mi suscripción</strong>".      <br>Puedes <strong>desactivar</strong> la compra automática en cualquier momento yendo a tu <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Cuenta</a>, y luego desde el evento en cuestión, puedes marcar/desmarcar la opción de compra automática.      <br>Como recordatorio, tu plaza inicial sigue siendo la misma, aunque realices cambios (añadir nuevos precios, cambiar el precio, el número de entradas que deseas, activar la compra automática...) en tu inscripción.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_modifier-achat-automatique" class="link --primary">¿Cómo cambiar la tarjeta de crédito utilizada para la compra automática?</a>      <br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>     <br>- <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo cambiar mi inscripción en la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Estoy buscando varias entradas, ¿esto reduce mis posibilidades de conseguir entradas?</a>     `,
    children: [],
  },
  cantActivateAutoPurchase: {
    id: 14,
    fragment: 'acheteur_refus-achat-automatique',
    question: 'No puedo activar la compra automática, ¿qué debo hacer?',
    score: 0,
    answer: `Para activar la compra automática, tu método de pago debe ser una tarjeta Visa o Mastercard y debe ser apta para la autenticación 3D Secure. Las tarjetas Amex y algunas tarjetas extranjeras no permiten activar esta función.      <br>
<br>Asegúrate de rellenar todos los campos (Precio máximo, Número de entradas necesarias, Fecha de caducidad del registro, Nombres de los titulares de las entradas, Tarjeta bancaria...).      <br>
<br>Si sigues teniendo dificultades con la activación de la compra automática, no dudes en <a href="/contact" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>ponerte en contacto con nosotros</a>.<br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     <br>- <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cuál es mi posición en la lista de espera?</a>     `,
    children: [],
  },
  modifyCreditCard: {
    id: 9,
    fragment: 'acheteur_modifier-achat-automatique',
    question: '¿Cómo cambio los datos de la tarjeta de crédito para la compra automática? ',
    score: 0,
    answer: `Si necesitas cambiar tu método de pago para la compra automática, puedes hacerlo directamente yendo a <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a>, y haciendo clic en el botón <strong>Cambiar mi inscripción</strong>. <img src="assets/img/faq/esES/achat/8-1-comment-modifier-la-carte-bancaire-renseignee-pour-l-achat-automatique.png" class="faq-screenshots" />Puedes ir directamente al paso 3, y luego hacer clic en <strong>Cambiar tarjeta de</strong> crédito. ⚠️ <strong>Ten en cuenta que tu método de pago debe ser una tarjeta Visa o Mastercard y debe ser apta para la autenticación 3D Secure. </strong> <img src="assets/img/faq/esES/achat/8-2-comment-modifier-la-carte-bancaire-renseignee-pour-l-achat-automatique.png" class="faq-screenshots" />Una vez que hayas introducido la información de tu nueva tarjeta de crédito, puedes hacer clic en <strong>Confirmar</strong>.      <br>
<br>
<br><span class="fw-700">Pregunta relacionada:</span>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     `,
    children: [],
  },
  autoPurchaseEventDate: {
    id: 8,
    fragment: 'acheteur_achat-automatique-jour-event',
    question: '¿Por qué no puedo activar la compra automática el día del evento?',
    score: 1,
    answer: `
    El día del evento, desactivamos la compra automática para todos para evitar sorpresas desagradables.
    <br>
    En el caso de los festivales, la compra automática se desactiva a partir del primer día del evento, aunque recibirás alertas por correo electrónico cuando haya entradas disponibles. :)
    <br><br>
    Esto no afectará a tu clasificación en la lista de espera, así que recuerde comprobar regularmente sus bandejas de entrada/correo electrónico no deseado.
    <br>
    <br>
    <br>
    <span class="fw-700">Preguntas relacionadas :</span>
    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">¿Puedo cambiar mi inscripción en la lista de espera?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">Busco varias entradas, ¿esto reduce mis posibilidades de conseguir entradas?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>
    `,
    children: [],
  },
  dontReceiveAlertSinceAutoPurchase: {
    id: 15,
    fragment: 'acheteur_alertes-achat-automatique',
    question: '¿Por qué no recibo más alertas por correo electrónico desde que se activó la compra automática?',
    score: 0,
    answer: `Si activas la compra automática, no se te enviarán avisos por correo electrónico. La compra automática te ahorra la molestia de comprobar tu correo electrónico.      <br>Cuando sea tu turno, ¡comprarás la entrada directamente!      <br>
<br>Sin embargo, el día del evento, desactivamos la compra automática para todos para evitar sorpresas desagradables. En este caso, si es tu turno, se te avisará por correo electrónico.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>     <br>- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>     <br>- <a href="/faq/acheteur#acheteur_modification-rang" class="link --primary">Si cambio mi inscripción, ¿pierdo mi plaza en la lista de espera? </a>     `,
    children: [],
  },
  SMSAlertAutomaticPurchase: {
    id: 75,
    fragment: 'acheteur_sms-achat-automatique',
    question: '¿Cómo puedo ser avisado por SMS de las compras automáticas? ',
    score: 0,
    answer: `A partir de ahora, además de recibir un aviso por correo electrónico, también podrás recibir un aviso por SMS si compras un billete automáticamente. 
    <br><br>
    Puedes introducir tu número de teléfono en el siguiente campo cuando actives la compra automática:
    <img src="assets/img/faq/esES/achat/75-1-sms-achat-auto.png" class="faq-screenshots" />
    <img src="assets/img/faq/esES/achat/75-2-sms-achat-auto.png" class="faq-screenshots" />
    Atención: algunos países en los que tenemos muy pocos usuarios no pueden recibir nuestros mensajes SMS.
    <br>
    Puedes encontrar tus entradas e inscripciones en tu área <a href="/mon-compte/mes-billets" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mi cuenta</a>.
    <br><br><br>
    <span class="fw-700">Preguntas relacionadas :</span>
    <br>
    - <a href="/faq/acheteur#acheteur_alertes-achat-automatique" class="link --primary">¿Por qué no recibo más alertas por correo electrónico desde que se activó la compra automática?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">¿Cómo funciona la lista de espera?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">¿Cómo puedo saber en qué lugar de la lista de espera me encuentro?</a>
    `,
    children: [],
  },
  deleteCardAutomaticPurchase: {
    id: 76,
    fragment: 'acheteur_supprimer-carte-bancaire',
    question: '¿Cómo suprimir la tarjeta bancaria utilizada para las compras automáticas?',
    score: 0,
    answer: `Reelax Tickets no almacena ningún dato bancario en su sistema. Los transferiremos directamente a nuestro proveedor de pago seguro, Mangopay.
    <br><br>
    Puedes eliminar la tarjeta de crédito utilizada para la compra automática directamente desde
    <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis listas de espera</a>.
    <img src="assets/img/faq/esES/achat/76-comment-supprimer-carte-bancaire.png" class="faq-screenshots" />
    <br>
    <span class="fw-700">Preguntas relacionadas :</span>
    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">¿Cómo activo/desactivo la opción de compra automática?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_modifier-achat-automatique" class="link --primary">¿Cómo cambiar la tarjeta de crédito utilizada para la compra automática?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_alertes-achat-automatique" class="link --primary">¿Por qué no recibo más alertas por correo electrónico desde que se activó la compra automática?</a>
    `,
    children: [],
  },
  pickupTicket: {
    id: 11,
    fragment: 'acheteur_recuperer-billets',
    question: '¿Cómo recupero las entradas después de haberlas comprado? ',
    score: 0,
    answer: `Recibirás sistemáticamente un correo electrónico en la dirección asociada a tu cuenta Reelax, en el que el botón Descargar mi entrada te permitirá obtener directamente la entrada en formato PDF.    <br>
<br><strong>Ten en cuenta que si no encuentras el correo electrónico, comprueba el correo no deseado o la pestaña de promociones de tu buzón. </strong>     <br>
<br>Si tienes una cuenta en Reelax Tickets, también puedes recuperar tu entrada en formato PDF desde tu Cuenta, en la pestaña <a href="/mon-compte/mes-billets" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span></a> <a href="/mon-compte/mes-billets" target="_blank"class="link --primary">Mis entradas</a>. <img src="assets/img/faq/esES/achat/10-1-comment-est-ce-que-je-recupere-les-billets-apres-mon-achat.png" class="faq-screenshots" />Si no tienes una cuenta, puedes recuperar tu entrada después de crear una cuenta <strong>con la misma dirección</strong> de correo electrónico. Una vez validada la dirección de correo electrónico, encontrarás tu entrada en la página <a href="/mon-compte/mes-billets" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>. <br>
<br>Es posible que en tu espacio personal aparezca el mensaje <strong>"Entrada disponible pronto"</strong>. Esto significa que se está creando tu nueva entrada para garantizarte el acceso al evento. Este retraso puede ser de 24 horas a 15 minutos el día del evento. <img src="assets/img/faq/esES/achat/10-2-comment-est-ce-que-je-recupere-les-billets-apres-mon-achat.png" class="faq-screenshots" />
<br><span class="fw-700">Pregunta relacionada :</span><br>- <a href="/faq/acheteur#acheteur_modifier-nom-billet" class="link --primary">¿Cómo puedo cambiar el nombre de mi entrada? </a>     `,
    children: [],
  },
  invoiceAfterBuying: {
    id: 65,
    fragment: 'acheteur_facture',
    question: '¿Puedo obtener una factura de mi compra?',
    score: 0,
    answer: 'Sí, si tienes una cuenta de Reelax Tickets, puedes encontrar la factura de nuestra tarifa de servicio en la página <a href="/mon-compte/mes-billets#Achat" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mis entradas</a>. <img src="assets/img/faq/esES/achat/23-puis-je-me-procurer-une-facture-pour-mon-achat.png" class="faq-screenshots" />De lo contrario, tienes que crear una cuenta utilizando la dirección de correo electrónico con la que compraste tu entrada.      <br>Para tu información, <strong>sólo cobramos una tasa</strong> de servicio sobre el importe de la venta de la entrada por el uso de nuestra plataforma en línea. El IVA (20%) se cobra en el momento del pago y está relacionado con la tasa de servicio total. El comprador paga el importe del pedido al vendedor a través de Reelax Tickets. Encontrarás un resumen de la transacción total en el correo electrónico de "Gracias por tu compra".      ',
    children: [],
  },
  emailMistakeInPurchase: {
    id: 61,
    fragment: 'acheteur_erreur-email',
    question: 'He introducido una dirección de correo electrónico incorrecta: ¿cómo recupero mi entrada?',
    score: 0,
    answer: `Puedes ponerte en contacto con nosotros en support@reelax-tickets.com con toda la información posible (nombre y apellidos del titular de la entrada, evento, categoría, fecha y hora de la compra, dirección de correo electrónico con el error de entrada).      <br>Entonces podremos devolverte la entrada por correo electrónico.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo pongo mi entrada a la venta?</a>     <br>- <a href="/faq/vendeur#vendeur_mail-confirmation" class="link --primary">No encuentro el correo electrónico de confirmación de mi cuenta, ¿qué debo hacer?</a>     <br>- <a href="/faq/vendeur#vendeur_aide-identite" class="link --primary">No consigo validar mi identidad, ¿qué debo hacer?</a>      `,
    children: [],
  },
  dontReceiveTicket: {
    id: 17,
    fragment: 'acheteur_probleme-reception-billet',
    question: 'No he recibido mi entrada aunque la he pagado, ¿qué puedo hacer?',
    score: 0,
    answer: `Esto puede deberse a un pago fallido, si no has esperado a que se complete el paso de pago 3D Secure. En este caso, se te enviará un correo electrónico en un plazo de 30 minutos para informarte de que el pago no se ha realizado correctamente. Todo lo que tienes que hacer es pulsar el enlace del correo electrónico para volver a intentar el pago. <img src="assets/img/faq/esES/achat/16-je-ne-recois-pas-mon-billet-alors-que-j-ai-paye-que-faire.png" class="faq-screenshots" />Si has superado este paso pero aún no has recibido tu entrada, es posible que el correo electrónico que contiene tu entrada se haya colado en tu bandeja de spam o promociones.      <br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/acheteur#acheteur_erreur-email" class="link --primary">Me he equivocado de dirección de correo electrónico: ¿cómo recupero mi entrada?</a>     <br>- <a href="/faq/acheteur#acheteur_recuperer-billets" class="link --primary">¿Cómo recupero mis entradas después de la compra?</a>     <br>`,
    children: [],
  },
  canICancelSale: {
    id: 60,
    fragment: 'acheteur_annuler-achat',
    question: 'Quiero cancelar mi compra, ¿me devuelven el dinero?',
    score: 0,
    answer: `Aparte de la cancelación o el aplazamiento del evento, de conformidad con el artículo L.121-28 del Código de Consumo francés, la compra de entradas en Reelax Tickets no puede ser objeto de un derecho de desistimiento. La entrada no es canjeable ni reembolsable.      <br>
<br>Sin embargo, si ya no te interesa tu entrada, puedes revenderla fácilmente desde tu <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Cuenta</a>.
<br>Por razones de seguridad, podemos limitar el número de veces que se puede revender una entrada. Si la venta no tiene éxito, haz clic en el botón <strong>Solicita ayuda</strong>. Recibiremos entonces su entrada y podremos orientarle.
<br>
<br>
<br><span class="fw-700">Preguntas relacionadas :</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">¿Cómo pongo mi entrada a la venta?</a>     <br>- <a href="/faq/vendeur#vendeur_non-vendu" class="link --primary">Si mi entrada no se vende, ¿puedo ir al concierto con ella?</a>    `,
    children: [],
  },
  buyer_whyReelax: {
    id: 62,
    fragment: 'acheteur_pourquoi-reelax',
    question: '¿Por qué Reelax Tickets es la plataforma de reventa más segura para comprar y vender tus entradas de segunda mano?',
    score: 0,
    answer: `Utilizamos el más alto protocolo de seguridad para protegerte del fraude en las entradas. En concreto, estamos conectados a las bases de datos de venta de entradas para comprobar si las entradas puestas a la venta en nuestra plataforma son válidas. Después, en cuanto se compra una entrada en nuestra plataforma, creamos instantáneamente una <strong>nueva entrada a tu nombre.</strong>      <br>
<br>
<br><span class="fw-700">Pregunta relacionada:</span>     <br>- <a href="/faq/acheteur#acheteur_frais-de-service" class="link --primary">¿A cuánto asciende la comisión de servicio por la compra de una entrada?</a>     <br>`,
    children: [],
  },
  differentPage: {
    id: 63,
    fragment: 'acheteur_site-different',
    question: '¿Por qué estoy en una página diferente del sitio web del organizador?',
    score: 0,
    answer: `Te encuentras en la página dedicada al evento en nuestra plataforma. El organizador ha utilizado la bolsa Reelax Tickets para <strong>asegurar la reventa de sus entradas.</strong>      <br>
<br>
<br><span class="fw-700">Pregunta relacionada:</span>     <br>- <a href="/faq/acheteur#acheteur_pourquoi-reelax" class="link --primary">¿Por qué Reelax Tickets es la plataforma de reventa más segura para comprar y vender tus entradas de segunda mano?</a>    <br>`,
    children: [],
  },
  softwareCompatibility: {
    id: 41,
    fragment: 'organisateur_logiciel-compatible',
    question: '¿Es mi software de venta de entradas compatible con Reelax Tickets?',
    score: 1,
    answer: `
    Todos los softwares de venta de entradas son <span class="fw-700">compatibles</span> con nuestro mercado de entradas.
    <br>
    Con nuestras soluciones asociadas, las integraciones son aún más profundas y sencillas.
    `,
    children: [],
  },
  priceSettingUp: {
    id: 42,
    fragment: 'organisateur_cout',
    question: '¿Cuánto cuesta montar un mercado para mi evento?',
    score: 1,
    answer: '<span class="fw-700">0 euros!</span> Reelax Tickets sólo cobra a los compradores de entradas.',
    children: [],
  },
  howSettingUp: {
    id: 48,
    fragment: 'organisateur_mise-en-place',
    question: '¿Cómo funciona el intercambio de entradas de principio a fin?',
    score: 0,
    answer: `1 - Rellene algunas preguntas para la <span class="fw-700">configuración de tu plataforma de intercambio de entradas</span> (fecha de launch, plan de asientos, imagen, color de tu plataforma, ...)
    <br><br>2 - Habilite la <span class="fw-700">conexión en tu software de venta de entradas</span> (dependiendo de tu software de venta de entradas y de tus distribuidores, podemos requerir un acceso restringido a tu software de venta de entradas).
    <br><br>3 - Firma nuestro <span class="fw-700">contrato de colaboración</span> y ¡ya está!
    <br><br>A partir de ese momento, te enviaremos tu login y tu plataforma de intercambio de entradas. ¡Todo lo que tienes que hacer es <span class="fw-700">compartirlo con tu público</span> (y estar seguro de que les encantará)!
    `,
    children: [],
  },
  timeSettingUp: {
    id: 43,
    fragment: 'organisateur_temps-installation',
    question: '¿Cuánto tiempo se tarda en montar un mercado?',
    score: 0,
    answer: `
    Normalmente montamos un mercado de entradas en <span class="fw-700">pocas horas</span>.
    <br>
    Tras una breve llamada con nuestro gestor de cuentas, configuramos el marketpace <span class="fw-700">con tu configuración</span> en un abrir y cerrar de ojos.
    `,
    children: [],
  },
  reelaxConsultant: {
    id: 56,
    fragment: 'organisateur_accompagnement',
    question: '¿Voy a estar acompañado por un asesor en Reelax Tickets?',
    score: 1,
    answer: `<span class="fw-700">Sí!</span>
    <br><br>Desde la creación hasta el final de tu evento e incluso mucho después, nuestros asesores le responderán en menos de una hora de media durante la semana y en menos de dos horas el fin de semana.
    <br><br>¡El objetivo número 1 de Reelax Tickets es la <span class="fw-700">satisfacción de nuestros organizadores asociados y de tu público</span>! No hay mayor orgullo para nuestro equipo que un socio que recomienda sinceramente nuestro servicio a otro organizador.
    `,
    children: [],
  },
  canIRefundBuyers: {
    id: 51,
    fragment: 'organisateur_annulation-report',
    question: 'Mi evento se pospone o se cancela, ¿puedo reembolsar a los últimos poseedores de entradas con Reelax Tickets?',
    score: 0,
    answer: `<span class="fw-700">¡Por supuesto!</span>
      <br><br> Para las entradas de tu <span class="fw-700">software de venta de entradas</span>, tiene la capacidad de reembolsar fácilmente a los compradores que lo deseen (ya sea a través de un archivo XML para enviar a tu banco, o a través de nuestro intermediario).
      <br><br>Con respecto a las entradas de <span class="fw-700">distribuidores</span>, reembolsamos automáticamente a los compradores ya que el pago a los vendedores se realiza después del evento para estas entradas.
      `,
    children: [],
  },
  presenceReelax: {
    id: 47,
    fragment: 'organisateur_sur-place',
    question: '¿Está presente Reelax Tickets el día del evento?',
    score: 1,
    answer: 'Si tu bolsa de entradas es víctima de tu propio éxito y usted <span class="fw-700">siente la necesidad</span> de apoyo en el gran día, un gestor de cuentas estará encantado de ir a ayudarle con el control de acceso.',
    children: [],
  },
  whoTakesCareSAV: {
    id: 50,
    fragment: 'organisateur_responsabilite-sav',
    question: '¿Quién se encarga del apoyo a los espectadores?',
    score: 1,
    answer: `Sigue siendo <span class="fw-700">nosotros</span> (pero sólo si estás de acuerdo).
      <br><br>Con la ambición de mejorar constantemente nuestro servicio, nos comprometemos a trabajar estrechamente con nuestros usuarios para identificar y comprender tus necesidades.
      `,
    children: [],
  },
  orga_whyReelax: {
    id: 45,
    fragment: 'organisateur_pourquoi-reelax',
    question: '¿Por qué Reelax Tickets es la plataforma más segura del mercado?',
    score: 0,
    answer: 'Reelax Tickets sólo permite la reventa de entradas de tus <span class="fw-700">eventos asociados</span>. Esta elección nos permite garantizar la seguridad de todas las entradas intercambiadas en nuestro servicio',
    children: [],
  },
  howSecure: {
    id: 46,
    fragment: 'organisateur_securite',
    question: '¿Cómo aseguramos los intercambios de entradas?',
    score: 1,
    answer: `
      Nuestro protocolo informático se conecta automáticamente a tu software de venta de entradas y a cualquier distribuidor para <span class="fw-700">verificar la validez</span> del código de acceso de la entrada puesta a la venta.
      <br><br>
      Entonces, en cuanto se vende una entrada en tu intercambio de entradas, la reempaquetamos, es decir, <span class="fw-700">invalidamos el código de acceso del vendedor</span> y asignamos <span class="fw-700">nuevo código de acceso al comprador</span>.
      <br><br>
      En algunos casos, cuando tu software de venta de entradas no nos permite cambiar los códigos de acceso, la entrada no se reembolsa, pero el dinero del vendedor queda bloqueado hasta el final del evento.
      `,
    children: [],
  },
  whyDoingThat: {
    id: 57,
    fragment: 'organisateur_demarche',
    question: '¿Por qué haces todo esto?',
    score: 0,
    answer: `
      El equipo fundador de Reelax Tickets está formado por 4 antiguos graduados de Mines-Telecom que comparten la necesidad de tener un <span class="fw-700">impacto positivo</span> en la sociedad así como una <span class="fw-700">pasión visceral por la música</span>, y esto a través de diferentes prismas (organizadores de eventos, músicos y espectadores).
      <br>
      <br>
      Después de haber observado con un centenar de organizadores que el mercado secundario de entradas era una completa aberración.
      El equipo lleva más de dos años desarrollando un protocolo informático <span class="fw-700">único en Europa</span> para <span class="fw-700">facilitar las transacciones de entradas</span> entre particulares y <span class="fw-700">suprimir el fraude</span>.
      Este protocolo es ahora <span class="fw-700">interoperable</span> con más de 20 softwares de venta de entradas y utilizado por muchos organizadores.
      <br>
      Dada la gran adopción de los organizadores franceses, estamos convencidos de que Francia puede convertirse en un ejemplo para muchos países europeos.
      <br>
      <br>
      If you are inspired by the adventure to clean up the secondary ticketing market in France and Europe or if you simply want to give us a helping hand, don't hesitate to <span class="fw-700">write us</span> at: <a class="link --primary" href="mailto:organisateurs@reelax-tickets.com">organisateurs@reelax-tickets.com</a>.
      `,
    children: [],
  },
  whereAreWe: {
    id: 58,
    fragment: 'organisateur_se-rencontrer',
    question: '¿Dónde encontrarnos?',
    score: 0,
    answer: `Reelax Tickets es una empresa francesa con sede en Rennes
      <br><br>
      Nuestro equipo navega entre nuestra oficina en IMT Atlantique (<span class="fw-700">Rennes</span>), nuestra segunda oficina en Telecom Paritech (<span class="fw-700">París 14</span>) y en los <span class="fw-700">mayores eventos de Francia</span>.
      <br><br>
      Finally you will always find someone to welcome you with open arms <span class="fw-700">here:</span> <a class="link --primary" href="mailto:organisateurs@reelax-tickets.com">organisateurs@reelax-tickets.com</a>
      `,
    children: [],
  },
  interferenceDashboard: {
    id: 55,
    fragment: 'organisateur_journal-operation-bordereau-recette',
    question: '¿Interfiere Reelax Tickets con mi registro de transacciones o recibo?',
    score: 1,
    answer: 'Reelax Tickets actúa como operador del sistema de venta de entradas del organizador registrando una actualización del código de acceso, pero <span class="fw-700">no actúa en el registro fiscal obligatorio de la operación de venta inicial ni en tu importe</span>',
    children: [],
  },
  reelaxInTicketingSystem: {
    id: 53,
    fragment: 'organisateur_systemes-billetteries',
    question: '¿Tengo que declarar Reelax Tickets en mi declaración del sistema informático de entradas?',
    score: 0,
    answer: '<span class="fw-700">No</span>, Reelax Tickets no es un sistema de venta de entradas tal y como lo define la legislación. Por lo tanto, no tiene <span class="fw-700">ninguna declaración que hacer</span>.',
    children: [],
  },
  integrabilityData: {
    id: 44,
    fragment: 'organisateur_logiciel-data',
    question: '¿Aparecen los datos de reventa de entradas en mi software de datos?',
    score: 1,
    answer: `
    Ya estamos conectados con <span class="fw-700">muchos softwares de datos</span>, podrá recibir toda la información de tu tablero directamente dentro de esos softwares.
    <br>
    Todos los datos también pueden ser <span class="fw-700">exportados</span> como formato csv o excel.
    `,
    children: [],
  },
  whoOwnsData: {
    id: 49,
    fragment: 'organisateur_proprietaire-donnees',
    question: '¿A quién pertenecen los datos generados por nuestros intercambios de entradas?',
    score: 1,
    answer: `
      Los datos generados por nuestros servicios <span class="fw-700">pertenecen a los organizadores</span>. Nuestro uso se limita estrictamente a la creación y gestión de tu bolsa de entradas. En ningún caso venderemos, cederemos, alquilaremos o utilizaremos tus datos para nuestros propios fines.
      <br><br>
      Además, todos nuestros datos están exclusivamente <span class="fw-700">alojados en Francia</span> y sujetos a la normativa <span class="fw-700">GDPR</span>.
      `,
    children: [],
  },
  myCgv: {
    id: 54,
    fragment: 'organisateur_cgv',
    question: '¿Puedo añadir mis T&C en Reelax Tickets?',
    score: 0,
    answer: '<span class="fw-700">Sí</span>, es esencial que todo tu público pueda tener acceso a tus términos y condiciones. Para ello, no tiene que hacer nada, nosotros nos encargamos de todo',
    children: [],
  },
  reelaxProvider: {
    id: 52,
    fragment: 'organisateur_politique-confidentialite',
    question: '¿Debo añadir a Reelax Tickets como proveedor en mi política de privacidad?',
    score: 0,
    answer: 'La mención no es obligatoria, pero <span class="fw-700">se recomienda</span> para ser lo más transparente posible con tu público.',
    children: [],
  },

};
